// styling for GridToggles
IconPaddingTop = 1px
IconPaddingLeft = 7px

.grid-toggle
    margin-left -15px
    margin-right -15px
    // Resolves a weird behavior where clicking on a button move other buttons around
    // see https://quorumanalytics.atlassian.net/browse/QPE-1952
    display flex
    flex-wrap wrap

    .grid-toggle-button
        margin-bottom 10px
        button
            display flex
            wrap nowrap
            justify-content center

            padding-left 9px
            padding-right 9px
            font-size 16px
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            .fa, .eu-icon-dropdown // vertically center left-justified icons and right-justified fa-locks
                align-self center

            // handle the case where we want to disable all buttons but show
            // a selected button distinguished from other disabled buttons
            &.btn-primary.disabled
                background-color #503ABD !important
                opacity unset !important

            .check-number
                border 1px solid white
                border-radius 100%
                width 25px
                font-size 16px

            .grid-toggle-image-icon
                max-height 150px

            .grid-toggle-option
                white-space nowrap
                text-overflow ellipsis
                overflow hidden

    .grid-toggle-column-button
        flex-direction column

    .grid-toggle-icon
        padding-top IconPaddingTop
        padding-left IconPaddingLeft
        font-size 15px
        position absolute
        left 25px

        // For rotated icons, need to flip padding to maintain consistent spacing
        &.fa-rotate-90
            padding-left IconPaddingTop
            padding-bottom IconPaddingLeft

        &.fa-rotate-180
            padding-bottom IconPaddingTop
            padding-right IconPaddingLeft

        &.fa-rotate-270
            padding-left IconPaddingTop
            padding-top IconPaddingLeft

    .grid-toggle-label-with-icon
        width 100%
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
    .grid-toggle-with-option
        // need to reapply text-overflow rules
        width calc(100% - 20px) // overwrites .grid-toggle-label-with-icon width if there are both icons on left and right
        text-overflow ellipsis
        overflow hidden

// .multi-grid-toggle
//     .grid-toggle-button
//         button
//             display flex
//             wrap nowrap

//             // for when icon is on the left (multi mode)
//             > span i
//                 position absolute
//                 left 28px
//                 top 13px

//             // icon/checkbox on the right
//             > i
//                 position absolute
//                 right 28px
//                 top 13px

//         .selected
//             i
//                 right 26px
