@import 'DESIGN.styl'

.help-i
    color HyperlinkColor
    font-size 20px
    cursor pointer

.cursor-pointer
    cursor pointer

.margin-left
    margin-left 3px

.tooltip
    &.show
        opacity: 1 !important
