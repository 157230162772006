@import DESIGN

.form-datetime-field
    margin-top ReduxFormMarginSize
    margin-bottom ReduxFormMarginSize

    .form-datetime-header
        display flex
        justify-content space-between
        align-items center
        margin-bottom 5px

        .form-datetime-error
            color ReduxFormErrorColor
        .form-datetime-warning
            color ReduxFormWarningColor

        .datetime-icon
            padding-left 5px

    label.control-label
        font-size ReduxFormFontSize

    .select-error-outline
        .rdt
            input
                border-color ReduxFormErrorColor

                &:focus
                    ReduxFormErrorFocus()

    .select-warning-outline
        .rdt
            input
                border-color ReduxFormWarningColor

                &:focus
                    ReduxFormWarningFocus()
