@import DESIGN

/**
 * There are a small number of style rules that we need to override
 * from components that are imported from the internal web application
 * (without their corresponding style rules).
 */

.control-label
    font-weight normal

.input-field.input-disabled.form-control
    cursor default !important

    &:focus
        box-shadow none

.hoverable
    &:hover
        cursor pointer

button.btn.btn-default-white
    background-color white
    border-color LightBorderColor

.modal-open
    .modal-backdrop
        background-color rgba(255, 255, 255, .5)


.StripeElement {
    box-sizing border-box

    height 40px

    padding 10px 12px

    border 1px solid transparent
    border-radius 4px
    background-color white

    box-shadow 0 1px 3px 0 #e6ebf1
    -webkit-transition box-shadow 150ms ease
    transition box-shadow 150ms ease
}

.StripeElement--focus {
    box-shadow 0 1px 3px 0 #cfd7df
}

.StripeElement--invalid {
    border-color #fa755a
}

.StripeElement--webkit-autofill {
    background-color #fefde5 !important
}

.modal.show {
    opacity 1 !important
}

.modal-backdrop.show {
    opacity .5 !important
}

.modal.show .modal-dialog {
  -webkit-transform translate(0, 0) !important
  -ms-transform translate(0, 0) !important
  -o-transform translate(0, 0) !important
  transform translate(0, 0) !important
}
