@import DESIGN

.form-checkbox-field
    margin-top ReduxFormMarginSize
    margin-bottom ReduxFormMarginSize

    button
        display flex
        justify-content center
        width 100%
        align-items center
        font-size ReduxFormFontSize

        i
            margin-right 10px

    .small-button-wrapper
        font-size 18px
        color QuorumBlue
        &.is-disabled
            color DarkGrey
            &:hover
                cursor default

        &:hover
            cursor pointer

        .small-button-icon
            margin-right 10px

    .new-action-center__checkbox-field, .new-action-center__checkbox
        background-color: transparent !important
        color: inherit
        border: none
        padding-left: 0
        padding-right: 0
        justify-content: left
        outline: none !important
        &:active
            box-shadow: none
        &:active:hover
            background: transparent
            border: none
        .form-checkbox-button-text
            display: inline-block
            vertical-align: middle
            font-size: 14px
            color: #495057
        .fa
            vertical-align: middle
            font-size: 19px
