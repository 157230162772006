@import DESIGN

.form-select-field
    margin-top ReduxFormMarginSize
    margin-bottom ReduxFormMarginSize

    .control-label
        margin-bottom 8px

    .form-select-header
        display flex
        justify-content space-between
    .form-select-error
        color ReduxFormErrorColor
    .form-select-warning
        color ReduxFormWarningColor
    .select-icon
        padding-left 10px
    .Select
        .Select__control
            .Select__value-container--is-multi
                .Select-value
                    line-height 25px
                    display inline-flex
                    align-items center

                    .Select-value-label
                        font-size ReduxFormInputSize

    // Change the aria color
    .is-focused
        .Select__control
            border-color QuorumBlue
            outline 0
            -webkit-box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(QuorumBlue, .6)
            box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(QuorumBlue, .6)

    .select-error-outline
        .Select__control
            border-color ReduxFormErrorColor

            &:focus
                ReduxFormErrorFocus()
        .NewSelect__control
            border-color ErrorRed

    .select-warning-outline
        .Select__control
            border-color ReduxFormWarningColor

            &:focus
                ReduxFormWarningFocus()
        .NewSelect__control
            border-color WarningMarigold

    &.new-action-center__select
        .form-select-header
            font-family: "Helvetica Neue"
            font-size: 14px
            font-style: normal
            font-weight: 500
            line-height: normal
        .form-select-container
           div[class*=" css-"][class*="-control"]
                display: flex
                align-items: center
                gap: 8px
                align-self: stretch
                border: 1px solid var(--Gray-5, #a9b5bf);
                border-radius: var(--xxs, 4px)
            div[class*=" css-"][class*="-container"]
                color: var(--Gray-5, var(--Gray-5, #A9B5BF))
                font-family: "Helvetica Neue"
                font-size: 16px
                font-style: normal
                font-weight: 400
                line-height: 16px
                div[class*=" css-"][class*="-ValueContainer"]
                    padding: 11.5px
                    div[class*=" css-"][class*="-Input"]
                        margin: 0
                        padding: 0
                span[class*=" css-"][class*="-indicatorSeparator"]
                    display: none
