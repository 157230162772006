@import DESIGN

.form-input-field
    margin-top 5px
    margin-bottom 0

    .control-label
        flex-shrink 0
        margin-bottom 8px

    .input-field-wrapper
        display flex

        .icon
            margin-left 10px
            color QuorumBlue
            width 32px
            align-self center

    .react-tel-input .form-control
    .input-field
    .ngg-input-field
        font-size ReduxFormInputSize
        width 100%
        line-height ReduxFormLineHeight
        border-radius 4px
        outline 0
        border 1px solid LightBorderColor
        :not(.react-tel-input)
            padding-left 10px
        height ReduxFormLineHeight
        transition border-color .15s ease-in-out, box-shadow .15s ease-in-out

        &::placeholder
            color QuorumGrey5

        &:focus
            border 2px solid QuorumBlue
            -webkit-box-shadow inset 0 0px 2px rgba(0, 0, 0, .075), 0 0 4px alpha(#000, .1)
            box-shadow inset 0 0px 2px rgba(0, 0, 0, .075), 0 0 4px alpha(#000, .1)

        &.ngg-input-field:focus
            border 1px solid QuorumBlue
            :not(.ngg-input-error-outline)
                box-shadow none

        &.ngg-input-error-outline
            border 1px solid LightBorderColor;
            box-shadow 0 0 0 2px ErrorPoppy;

        &.textarea-field
            height auto
            line-height 25px

        &.input-error-outline
            border 2px solid ErrorPoppy

    label
        font-size ReduxFormFontSize

        &:focus
            ErrorFocus()

    .input-warning-outline
        border-color ReduxFormWarningColor

        &:focus
            WarningFocus()

    .input-disabled
        background-color QuorumGrey1

        &:hover
            cursor not-allowed

    .form-input-header
        display flex
        justify-content space-between
        width 100%

    .form-input-error
        color ReduxFormErrorColor
    .form-below-input-error
        font-size: 15px
        color ErrorPoppy
        margin-top 6px

    .form-input-warning
        margin-left 10px
        color ReduxFormWarningColor
    .input-icon
        padding-left 10px
    .below-input-icon
        font-size: 15px
        padding-right 6px
    .form-input-header
        display flex
        justify-content space-between
        align-items center
