@import '../DESIGN.styl'
@import '../STYLUS_FUNCTIONS.styl'
SelectorBackgroundColor = QuorumGrey1 //#D7DBDE // QuorumGrey1 post one page
SelectorBorderColor = QuorumGrey5 // #999 // QuorumGrey5 post one page
.quorum-obj-selector
.quorum-obj-browser
    &
        background-color SelectorBackgroundColor // #D7DBDE
        border 1px SelectorBorderColor solid
        border-radius 4px
        font-size 16px

    .quorum-tag-obj-search
        width 100%
        padding 15px 15px
        background-color SelectorBackgroundColor
        border-top 1px SelectorBorderColor solid
        border-right 0px transparent
        border-left 0px transparent
        border-bottom 0px transparent
        border-bottom-right-radius 4px
        border-bottom-left-radius 4px
        &:focus
            outline none
// put this outside the quorum-obj-selector because quorum-selected-objects are used on their own elsewhere
.quorum-selected-objects
    list-style none
    display flex
    padding 4px
    margin 0px
    flex-wrap wrap
    min-height 61px
    max-height 167px  // What I found to be the best height
    overflow-y auto

    .quorum-tag
        cursor default
        max-height 45px
        background-color white
        border 1px #999 solid
        border-radius 4px
        margin 4px
        padding 10px 10px 10px 10px
        position relative

        &.list-adder-tag
            color #666
            a
                color #666
        &.blue-tag-1
            background-color white
            color QuorumBlue
            a
                color QuorumBlue
        &.blue-tag-2
            background-color QuorumBlue
            a
                color white
        &.purple-tag-1
            background-color white
            a
                color LightPurple
        &.purple-tag-2
            background-color LightPurple !important
            a
                color white
        &.default
            // remove the extra spacing on default options
            a > .quorum-tag-text
                margin-right 0px !important
                cursor default !important
        a
            cursor default
            text-decoration none

            .quorum-tag-text-container
                &.clickable
                    cursor pointer
                .quorum-tag-text
                    white-space nowrap
                    text-overflow ellipsis
                    overflow hidden
                    margin-right 25px
                    display inline-block
                    width -webkit-fill-available
                    &.has-additional-icon, &.has-caret
                        margin-right 45px
                    &.has-additional-icon.has-caret
                        margin-right 55px

        .tag
            position absolute
            top 50%
            transform translateY(-50%)
            right 10px
            cursor pointer

        .remove-tag
            &:hover
                opacity .8

        .keep-tag
            right 12px

        .fa-angle-right
            right 22px
            top 26%
            font-size 21px
            font-weight 200

        .additional-tag
            margin-right 5px
