@import '../DESIGN.styl'
@import '../STYLUS_FUNCTIONS.styl'
TypeaheadBorderColor =  #999 // Shoudl be QuorumGrey5

.quorum-typeahead-div
    & > .quorum-typeahead-results
        position absolute
        z-index 2000
        overflow-y scroll
        max-height 450px
        border-radius 4px
        border 1px TypeaheadBorderColor solid
        margin-top 5px  // spacing for the top
        margin-left -1px
        width calc(100% - 40px)

        .list-group
            margin-bottom 0px

            &:first-child
                .group-label:first-child
                    border-top none!important

            &:last-child
                .group-label:last-child
                    border-bottom none!important

            .list-group-item
                font-size 18px
                color QuorumBlue
                cursor pointer
                border-top 1px TypeaheadBorderColor solid
                border-bottom 1px TypeaheadBorderColor solid
                border-left 0px TypeaheadBorderColor solid
                border-right 0px TypeaheadBorderColor solid
                border-radius 0px
                &:last-child
                    border-bottom 0px TypeaheadBorderColor solid
                &:not(.group-label):hover
                    background-color QuorumBlue
                    color white !important
                &.group-label
                    cursor default
                &.group-label:first-child
                    font-weight 600
                    border-top 1px TypeaheadBorderColor solid

