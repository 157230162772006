@import 'STYLUS_FUNCTIONS.styl'
@import 'DESIGN.styl'
@import 'SweetAlertTwo'

/* Top bar and side bar styles */
@font-face
    font-family 'StateFaceRegular'

    src url('../fonts/stateface/StateFace-Regular.eot') format('embedded-opentype'),
            url('../fonts/stateface/StateFace-Regular.woff') format('woff'),
            url('../fonts/stateface/StateFace-Regular.ttf') format('truetype'),
            url('../fonts/stateface/StateFace-Regular.svg#StateFaceRegular') format('svg')
    font-weight normal
    font-style normal

/* To Navigation Style */
*,
*:after,
*::before
    -webkit-box-sizing border-box
    -moz-box-sizing border-box
    box-sizing border-box

html
  /* Ensure focusing on an input scrolls the component into view, and doesn't leave it hidden behind the QuorumFrame */
  scroll-padding-top calc(40px + var(--navbar-height) + var(--header-height))
  scroll-padding-bottom 80px

body
    background-color White
    height 100%
    margin 0
    -webkit-font-smoothing antialiased

#page-loading
    display none

.gn-menu-main,
.gn-menu-main ul.top-bar
    margin 0px
    padding 0px
    background White
    list-style none
    text-transform none
    font-weight 300
    line-height 60px


.gn-menu-main
    display flex
    position fixed
    width 100%
    height 60px
    background-color QuorumBlue
    border-bottom thin solid TopBarBorderColor
    border-right thin solid TopBarBorderColor
    z-index 10

.gn-menu-main i
    -webkit-font-smoothing antialiased

.gn-menu-main a
    display block
    height 100%
    color TopBarTextColor
    text-decoration none
    cursor pointer

// control labels throughout the site
label.control-label
    font-size FormLabelSize
    font-weight normal
    color Gray7
    margin-bottom 0

.notif-item:hover,
.notif-item * a:hover
    background rgba(48, 62, 96, 0.13) !important

.gn-menu-main :not(.gn-trigger):not(.nav-bar-logo) a:not(.select2-choice):not(.quorum-tag a):hover,
.gn-menu-main .open,
#dropdown-notifications li:hover:first-child,
#dropdown-notifications li:hover:last-child
    background TopBarHoverColor!important
    color white

#dropdown-notifications > li div a:hover
    background none!important

#state-select-search > a:hover:not(.fa-search),
#help-search input
    color black!important

.gn-trigger a:hover
    background-color TopBarHoverColor

.gn-menu-main > li
    border-right none
    border-left thin solid #c6d0da

.gn-menu-main > li:not(#search)
    flex-shrink 0

.gn-menu-main > li:hover:not(#search):not(.gn-trigger):not(.nav-bar-logo)
    background TopBarHoverColor !important
    cursor pointer

a.gn-icon.gn-icon-menu:hover
    background-color TopBarHoverColor !important
    cursor pointer

.gn-menu-main .dropdown-menu
    font-size 15px
    min-width 250px
    border-radius 0px
    border-color TopBarBorderColor
    margin-left -1px

.dropdown-menu.alert-select
    left auto
    margin-left -432px
    margin-top 0px

.dropdown-menu.jip
    left auto
    margin-left -426px !important

.dropdown-menu.alert-select li a:hover .beta_label
    background-color #fff
    color TopBarHoverColor

.gn-menu-main .dropdown-menu a > i:not(.fa-search):not(.remove-tag):not(.fa-lock):not(#launch-chat-icon)
    float left
    margin-left -5px
    margin-top 3px

.top-notifications
    height 45px
    font-weight 300
    font-size 22px
    color #fff!important
    background-color QuorumBlue
    line-height 45px

.bottom-notifications
    padding-top 0px
    padding-bottom 0px
    margin-top 0px

.bottom-notifications a
    color black
    font-weight 300
    font-size 19px
    line-height 45px

.notif-item
    font-size 13px!important
    font-weight 400!important
    line-height 13px!important
    text-align left!important
    padding-top 16px!important

// #dropdown-notifications > li:nth-child(3).notif-item
//   margin-top: 0px

.notif-item div
    line-height normal!important

.notif-item div a,
.notif-item div a:hover
    height 100%!important
    display inline!important
    line-height normal!important
    font-size 15px!important
    -webkit-font-smoothing antialiased!important
    font-weight 400!important
    background none!important
    color #854ea4!important
    padding 0!important

.dropdown-notifs > li:first-child
    padding 0px
    margin-bottom 10px

.dropdown-notifs > li > a
    width 498px!important

#placeholder-notification
    padding-top 0px
    padding-bottom 0px
    font-size 15px!important

.dropdown-menu.help-select
    left auto
    margin-left -145px!important

.dropdown-menu.interaction-logger-select
    left auto
    // menu width is 250px, button width is 74px, so this is 250 - 74 = 176px
    margin-left -176px!important

.dropdown-menu.state-select
    left auto

.dropdown-menu.user-select
    right 0
    left auto

.dropdown-menu.user-options
    right auto

// interaction form styling
.jip
    padding-left 20px!important
    padding-right 20px!important
    padding-bottom 20px!important

    .top-notifications
        width calc(100% + 40px)
        margin-left -20px

.gn-menu-main .dropdown-menu ul.interaction-logger-form
    line-height normal
    font-weight 400!important
    InteractionForm()
    // color #0E121C

    li:first-child
        text-align left

    a.editable.select2
        display inline-block
        margin-top -3px

    li a:not(.select2-choice)
        height auto!important
        width auto!important
        vertical-align middle
        font-weight 400
        font-size inherit
        text-align center
        padding-top 0px
        padding-bottom 0px
        line-height normal
        padding-right 0px
        padding-left 0px
        display inline
        color HyperlinkColor
        -webkit-font-smoothing antialiased!important

    a.select2-choice:hover
        background-color #fff
        background none!important
        background-image -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff))!important
        background-image -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%)!important
        background-image -moz-linear-gradient(center bottom, #eee 0%, #fff 50%)!important
        // filter: unquote("progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0) !important");
        background-image linear-gradient(top, #fff 0%, #eee 50%)!important
        color black

    #dashboard-to-staffer-container
        display none

.gn-menu-main .dropdown-menu .divider
    margin 0

    &.custom-region-divider
        height 18px
        font-size 11px
        line-height 18px
        font-weight 400
        color QuorumGrey2
        background-color QuorumGrey1

.gn-menu-main .dropdown-menu .custom-selection-name-input
    border-radius 0px

.gn-menu-main span.stateface,
.gn-menu-main i.fa
    -webkit-font-smoothing antialiased!important

.gn-menu-main .dropdown-menu li a:not(.select2-choice):not(#editable-filter):not(.quorum-tag-button):not(.org-owners-ref):not(#launch-chat),
.states-overflow li a:not(.select2-choice),
.preset-regions li a
    height 40px
    width 250px
    vertical-align middle
    display table-cell
    font-weight 300
    font-size 19px
    text-align right
    padding-top 10px
    padding-bottom 9px
    line-height 27.142858505249px
    padding-right 20px
    padding-left 20px

// Make the states inside the dropdowwn overflow also change color on hover
.gn-menu-main .dropdown-menu .states-overflow li:hover,
.preset-regions li:hover
    background-color TopBarHoverColor
    color white

.dropdown-menu .stateface
    font-size 24px
    float left
    margin-top -3px

.dropdown-menu
  .unchecked
    .checked-icon
      display none

  .checked
    .unchecked-icon
      display none

.dropdown-search
  padding 0

.dropdown-search a
  padding 0!important
  background QuorumBlue!important

.dropdown-search i
  color #fff
  margin-right 12px

.dropdown-search input
  width calc(100% - 42px)
  height 40px
  border 0
  padding-left 10px
  padding-right 20px
  text-align right

.dropdown-search input:focus
  border-color QuorumBlue
  outline 0
  -webkit-box-shadow inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(48,62,96,0.6)
  box-shadow inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(48,62,96,0.6)

.dropdown-search a:hover
  color black

.dropdown-menu.state-select
  display block !important

.dropdown-menu.state-select .preset-name
  border 0
  width 100%
  font-weight 300
  font-size 19px

.dropdown-menu.state-select ul
  max-height 250px
  overflow-y auto
  padding-left 0px !important
  padding-right !0px !important

.dropdown-menu.state-select ul.preset-regions
  max-height 150px
  li:hover
    i.remove-custom-preset
      opacity 1 !important

  i.custom-preset-icon
    float left
    margin-top 2px
    font-size 23px
    &:hover
      color QuorumGrey2

    &.remove-custom-preset
      margin-left 4px
      opacity 0

    &.edit-custom-preset
      margin-left 2px
      CogRotations()

    &.fixed-custom-preset
      margin-left 2px

.dropdown-menu.state-select ul li a
  color black

.dropdown-menu.state-select #finalize-preset
  padding-top 4px
  padding-bottom 4px

.states-overflow li:nth-child(odd),
.preset-regions li:nth-child(odd),
#select-federal,#select-local, #create-preset
  background-color: DropdownStripeColor

.states-overflow li:hover,
.preset-regions li:hover
  background-color #503ABD !important
  a
    color white !important

.state-choose-button
  padding-left 15px!important
  padding-right 13px!important

.state-choose-button > span
  float left
  font-size 24px
  margin-top -2px
  padding-right 6px

.state-choose-button > span.stateface-us
  padding-right 3px

.state-choose-button > span.stateface-wi
  margin-top -4px

.state-choose-button i.fa-angle-down,
.help-button i.fa-angle-down,
.log-interaction-button i.fa-angle-down,
.user-button i.fa-angle-down,
.alert-button i.fa-angle-down
  font-size 28px
  float right
  margin-top 15px
  margin-left 10px

/* help button */
.help-button,
.user-button,
.log-interaction-button
  padding-left: 14px!important
  padding-right: 12px!important

.user-button i.fa-user
  margin-top 0px!important
  padding-right 9px

.alert-button
  padding-left 13px!important
  padding-right 13px!important

.alert-button i.fa-angle-down
  margin-left 5px!important

.help-button i.fa-angle-down,
.log-interaction-button i.fa-angle-down
  margin-left 7px!important

.gn-menu a
  background-color QuorumBlue
  SidebarGradient()

.gn-menu li:not(:first-child),
.gn-menu li li
  border-top thin solid SidebarBorderColor

.gn-menu li:last-child,#more-options-sidebar
  border-bottom thin solid SidebarBorderColor

.extra-submenu
  padding-left 0px !important

.extra-submenu li:last-child
    border-bottom 0

/*.post-data-options a {
  border-top: 1px solid SidebarBorderColor!important;
}*/
/* icon-only trigger (menu item) */
.gn-menu-main li.gn-trigger
  position relative
  width 59px
  border 0
  -webkit-touch-callout none
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  .q-loading-logo-2
    padding 14px
    padding-right 17px
    padding-top 9px

.gn-menu-main > li.right
  float right
  border-right none
  border-left thin solid TopBarBorderColor

.gn-menu-main > li > a,
.gn-menu-main > li > .no-click
  font-size 18px

.gn-menu-main:after
  display table
  clear both
  content ""

.gn-menu-wrapper
  position fixed
  top 60px
  bottom 0
  left 0
  overflow hidden
  width 60px
  border-top thin solid TopBarBorderColor
  border-right thin solid SidebarBorderColor
  background white
  margin-top -1px
  /*    -webkit-transform: translateX(-60px);
    -moz-transform: translateX(-60px);
    transform: translateX(-60px);*/
  -webkit-transition: -webkit-transform 0.3s, width 0.3s
  -moz-transition: -moz-transform 0.3s, width 0.3s
  transition: transform 0.3s, width 0.3s

.gn-scroller
  position: absolute
  overflow-y: scroll
  width: 370px
  height: 100%
  SidebarGradient()

.gn-menu
  border-bottom: 0
  text-align: left
  font-size: 16px

.gn-menu-main *
  -webkit-font-smoothing: subpixel-antialiased

// .gn-submenu li
//   overflow: hidden
//   height: 0
//   -webkit-transition: height 0.3s
//   -moz-transition: height 0.3s
//   transition: height 0.3s

// .gn-submenu li a
//   color: #c1c9d1

.gn-menu li:hover .gn-icon::before
  color white
  fill white

.gn-menu li:hover > a
  background TopBarHoverColor!important
  color white
  svg
    path
      fill white

  i.fa-info-circle
      display inline
      position absolute
      right 75px
      margin-top 21px

.gn-menu li a
  display flex
  align-items center
  color black
  overflow hidden
  &.gn-icon-active
    color white
    svg
      path
        fill White
  &.gn-icon-active.first-active-item
    box-shadow inset 0px 4px 1px -4px white
  svg
    font-size 29px
    width 60px !important
    path
      fill QuorumBlue

  i.fa-info-circle
      display none

.gn-icon::before
  width: 60px
  text-align: center
  font: normal normal normal 14px/1 FontAwesome
  speak: none
  -webkit-font-smoothing: antialiased
  color: QuorumBlue
  font-size: 30px
  pointer-events: none

.gn-icon-active::before
  width: 60px
  text-align: center
  font: normal normal normal 14px/1 FontAwesome
  speak: none
  -webkit-font-smoothing: antialiased
  color: white
  font-size: 30px
  pointer-events: none
  svg
    path
      fill white

.gn-icon.gn-shift-up::before
  margin-top: 15px!important

.gn-menu li:hover .extra-submenu .gn-icon::before
  color: QuorumBlue!important

.gn-menu li:hover .extra-submenu li:hover .gn-icon::before
  color: #fff!important

span.sidebar-text-active
    color #fff

.gn-icon
    height 60px!important

.gn-menu li:not(#more-options-sidebar):hover::after
  content: "\f105"
  font: normal normal normal 14px/1 FontAwesome
  line-height: 1
  position: absolute
  right: 50px
  font-size: 30px
  margin-top: -46px
  color: #fff
  pointer-events: none

.gn-menu li#more-options-sidebar:hover span.sidebar-text::after
  content: "\f107"
  font: normal normal normal 14px/1 FontAwesome
  line-height: 1
  position: absolute
  right: 50px
  font-size: 30px
  top 0px
  margin-top: -46px
  color: #fff
  pointer-events: none

.gn-menu li.active a
  background: QuorumBlue

.gn-menu-wrapper:hover li.active::after
  content: "\f138"
  font: normal normal normal 14px/1 FontAwesome
  line-height: 1
  position: absolute
  right: 46px
  font-size: 27px
  margin-top: -42px
  color: white
  pointer-events: none

.gn-menu-wrapper:hover li.active:hover::after
  color: #fff

/* styles for opening menu */
.gn-menu-wrapper:hover,
.gn-menu-wrapper:hover
  -webkit-transform: translateX(0px)
  -moz-transform: translateX(0px)
  transform: translateX(0px)

.gn-menu-wrapper:hover
  width: 340px

.gn-menu-wrapper:hover .gn-submenu li
  height: 60px

@media screen and (max-width: 422px)
  .gn-menu-wrapper:hover
    -webkit-transform: translateX(0px)
    -moz-transform: translateX(0px)
    transform: translateX(0px)
    width: 100%

  .gn-menu-wrapper:hover .gn-scroller
    width: 130%

/* quicksearch bar */
.quicksearch-wrapper
  padding-top 7px
  padding-left 20px
  flex-grow 1

.quicksearch-wrapper .input-group,
.search-wrapper .input-group-addon
  height: 45px
  padding-right 20px
  cursor: pointer

.search-wrapper .input-group input
    height 45px
    font-weight 300
    font-size 19px

.quicksearch-wrapper .input-group-addon,
.search-wrapper .input-group-addon
  min-width 45px
  padding-left 0px
  padding-right 0px
  background rgba(255,255,255,0.95)

.search-wrapper.search-wrapper-lg .input-group-addon
  min-width 59px
  background QuorumBlue

.quicksearch-wrapper .fa-search,
.quicksearch-wrapper .fa-search-plus,
.search-wrapper i
  font-size 24px
  margin-left 0px
  color QuorumBlue

.search-wrapper.search-wrapper-lg i
  color: #fff
  font-size: 30px

.search-wrapper.search-wrapper-lg input
  font-size: 22px

.quicksearch-wrapper input
  width: 100%
  font-size: 16px
  font-weight: 300
  -webkit-font-smoothing: subpixel-antialiased

.quicksearch-wrapper input
  height: 45px

/* stateface */
.stateface:before
  font-family: StateFaceRegular
  margin-right: 5px
  font-style: normal

.stateface-replace
  text-indent: -999em
  display: inline-block
  position: relative
  min-width: 1em

.stateface-replace:before
  position: absolute
  left: 0
  top: 0
  text-indent: 0

.stateface-ak:before,
.stateface-02:before
  content: "A"

.stateface-al:before,
.stateface-01:before
  content: "B"

.stateface-ar:before,
.stateface-05:before
  content: "C"

.stateface-az:before,
.stateface-04:before
  content: "D"

.stateface-ca:before,
.stateface-06:before
  content: "E"

.stateface-co:before,
.stateface-08:before
  content: "F"

.stateface-ct:before,
.stateface-09:before
  content: "G"

.stateface-dc:before,
.stateface-11:before
  content: "y"

.stateface-de:before,
.stateface-10:before
  content: "H"

.stateface-fl:before,
.stateface-12:before
  content: "I"

.stateface-ga:before,
.stateface-13:before
  content: "J"

.stateface-hi:before,
.stateface-15:before
  content: "K"

.stateface-ia:before,
.stateface-19:before
  content: "L"

.stateface-id:before,
.stateface-16:before
  content: "M"

.stateface-il:before,
.stateface-17:before
  content: "N"

.stateface-in:before,
.stateface-18:before
  content: "O"

.stateface-ks:before,
.stateface-20:before
  content: "P"

.stateface-ky:before,
.stateface-21:before
  content: "Q"

.stateface-la:before,
.stateface-22:before
  content: "R"

.stateface-ma:before,
.stateface-25:before
  content: "S"

.stateface-md:before,
.stateface-24:before
  content: "T"

.stateface-me:before,
.stateface-23:before
  content: "U"

.stateface-mi:before,
.stateface-26:before
  content: "V"

.stateface-mn:before,
.stateface-27:before
  content: "W"

.stateface-mo:before,
.stateface-29:before
  content: "X"

.stateface-ms:before,
.stateface-28:before
  content: "Y"

.stateface-mt:before,
.stateface-30:before
  content: "Z"

.stateface-nc:before,
.stateface-37:before
  content: "a"

.stateface-nd:before,
.stateface-38:before
  content: "b"

.stateface-ne:before,
.stateface-31:before
  content: "c"

.stateface-nh:before,
.stateface-33:before
  content: "d"

.stateface-nj:before,
.stateface-34:before
  content: "e"

.stateface-nm:before,
.stateface-35:before
  content: "f"

.stateface-nv:before,
.stateface-32:before
  content: "g"

.stateface-ny:before,
.stateface-36:before
  content: "h"

.stateface-oh:before,
.stateface-39:before
  content: "i"

.stateface-ok:before,
.stateface-40:before
  content: "j"

.stateface-or:before,
.stateface-41:before
  content: "k"

.stateface-pa:before,
.stateface-42:before
  content: "l"

.stateface-pr:before,
.stateface-72:before
  content: "3"

.stateface-ri:before,
.stateface-44:before
  content: "m"

.stateface-sc:before,
.stateface-45:before
  content: "n"

.stateface-sd:before,
.stateface-46:before
  content: "o"

.stateface-tn:before,
.stateface-47:before
  content: "p"

.stateface-tx:before,
.stateface-48:before
  content: "q"

.stateface-us:before
  content: "z"

.stateface-ut:before,
.stateface-49:before
  content: "r"

.stateface-va:before,
.stateface-51:before
  content: "s"

.stateface-vt:before,
.stateface-50:before
  content: "t"

.stateface-wa:before,
.stateface-53:before
  content: "u"

.stateface-wi:before,
.stateface-55:before
  content: "v"

.stateface-wv:before,
.stateface-54:before
  content: "w"

.state-choose-button i.fa-bank,
.state-choose-button i.fa-compass
  padding-right: 8px


.stateface-wy:before,
.stateface-56:before
  content: "x"

/* Begin rest of styles */
.arrow
  float: right

.max-press-block
  max-height: 120px
  overflow-x: hidden


/* Wrappers */
/* ------------------------------- */
.notif-item > div > span > a
  padding 0px!important

.icon_wrapper
  border 3px solid #95a5a6
  padding 15px
  border-radius: 100%

.main
  background-color: #fff

.main-public-sheets-container
  width calc(100% + 15px) !important

.main-public-sheets
  min-height calc(100vh - 60px)
  margin-left 15px
  overflow hidden
  width calc(100% + 15px)

#content
  display flex
  height calc(100vh - 60px)

#container > .row
  overflow hidden
  padding-right 15px

@media (min-width:300px)
  .main
    position inherit
    padding 60px 0 0 60px
    height 100vh

  .main-public-sheets
    position inherit
    min-height calc(100vh - 60px)
    margin-left 15px
    width calc(100% + 15px)

  #container
    width 100%

  #container > .row
    width 100%

@media (max-width:768px)
  #container
    width 100%

.page-header
  font-weight: 400

.header-spacer
  margin-bottom: 22px

/* Bootstrap overrides */
.pager a
  color: #fff!important

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span
  color: #854ea4!important

.pager
  cursor: default
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.introjs-helperNumberLayer
  line-height: 11px!important

/* For Bootstrap Validator */
.has-error .form-control
  border-color: red

.has-success .form-control,
.has-warning .form-control
  border: thin solid #dce4ec

.has-error .help-block,
.has-error .form-control-feedback
  color: red

.has-success .help-block,
.has-success .form-control-feedback
  color: #4D2D5F

.has-warning .help-block,
.has-warning .form-control-feedback
  color: #3D3262

#record-dropdown
  margin-left: 390px
  width: calc(100% - 390px - 15px)

.dropdown-menu-large span
  font-size: 0.8em

.see_all_dropdown,
.dropdown-menu-large > li
  padding: 0px!important

.dropdown_list_item
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.dropdown-large span
  font-size: 13.5px

.btn-select-container,
.btn-select-bill-container,
.btn-select-vote-container,
.btn-select-amendment-container,
.btn-select-press-container,
.btn-select-statement-container,
.btn-select-staffer-container,
.btn-select-tweet-container,
.btn-download-press-container,
.btn-download-statement-container
  position: absolute
  width: 30px!important
  height: 30px!important
  border: thin solid QuorumGrey1
  top: 96px
  background: white
  right: 15px
  border-top-left-radius: 5px
  border-bottom-right-radius: 5px
  color: QuorumBlue

.btn-select-fbpost-container
  top: auto!important
  margin-top: -48px

.btn-select-container:hover,
.btn-select-bill-container:hover,
.btn-select-vote-container:hover,
.btn-select-amendment-container:hover,
.btn-select-press-container:hover,
.btn-select-statement-container:hover,
.btn-select-staffer-container:hover,
.btn-select-tweet-container:hover,
.btn-download-press-container:hover,
.btn-download-statement-container:hover
  cursor: pointer
  background-color: #eee!important
  border-color: QuorumGrey5

.btn-select-container.right-end
  border-top-left-radius: 0px

.btn-select-container.left-end
  border-bottom-right-radius: 0px

.btn-select-container.middle
  border-bottom-right-radius: 0px
  border-top-left-radius: 0px

.btn-select-container.first
  margin-right: 0px

.btn-select-container.second
  margin-right: 29px

.btn-select-container.third
  margin-right: 58px

.btn-select-container.fourth
  margin-right: 87px

.btn-select-container.fifth
  margin-right: 116px

.btn-select-container.sixth
  margin-right: 145px

.btn-select-staffer-container.btn-select-container.first
  margin-right: 5px

.btn-select-staffer-container.btn-select-container.second
  margin-right: 34px

.btn-select-staffer-container.btn-select-container.third
  margin-right: 63px

.btn-select-staffer-container.btn-select-container.fourth
  margin-right: 92px

.btn-select-staffer-container.btn-select-container.fifth
  margin-right: 121px

.btn-select-staffer-container.btn-select-container.sixth
  margin-right: 150px

.btn-select-container .fa-user-plus
  margin-left: 6px
  margin-top: 9px

.btn-select-container .fa-download
  margin-top: 10px
  margin-left: 6px

.btn-select-container .fa-times-circle
  margin-top 9px
  margin-left 6px

.btn-select-container .fa-plus-circle
  margin-top: 9px
  margin-left: 6px

.btn-select-container .fa-envelope
  margin-top: 9px
  margin-left: 5px

.btn-select-container .fa-square-o,
.btn-select-container .fa-check-square-o
  margin-left: 7px
  margin-top: 10px

.btn-select-container i,
.btn-select-person,
.btn-select-bill,
.btn-select-vote,
.btn-select-amendment,
.btn-select-press,
.btn-select-statement,
.btn-select-staffer,
.btn-select-tweet,
.btn-download-press,
.btn-download-statement
  margin-left: 6px
  margin-top: 8px
  color: QuorumBlue

.btn-hovered
  background-color: white!important
  border: thin solid QuorumGrey1!important

.btn-select-bill-container
  top: 92px

.btn-select-vote-container
  top: 88px

.btn-select-statement-container
  top: 204px

.btn-select-amendment-container
  top: 88px!important

.btn-select-staffer-container
  top: 190px
  border-color: QuorumGrey5

.btn-select-press-container
  top: 210px

.btn-select-tweet-container
  top: 130px

.btn-select-fbpost-container .post-size-1

.btn-select-fbpost-container .post-size-2

.btn-select-fbpost-container .post-size-3
  top: 516px

.btn-select-person-large
  margin-left: 1px
  margin-top: 8px
  color: QuorumBlue

.btn-select-person-container.large
  height: 30px
  width: 30px
  border: thin solid QuorumGrey1
  position: relative
  float: right
  top: -48px
  border-top-left-radius: 4px
  border-bottom-right-radius: 4px
  background-color: white

.btn-select-person-container.small
  height: 30px
  width: 30px
  border: thin solid QuorumGrey1
  position: relative
  float: right
  top: -48px
  border-top-left-radius: 4px
  border-bottom-right-radius: 4px
  background-color: white

.btn-select-person-small
  margin-left: 6px
  margin-top: 8px
  color: QuorumBlue

.btn-select-person-container.xsmall
  height: 30px
  width: 30px
  border: thin solid QuorumGrey1
  position: absolute
  right: 15px
  top: 24px
  border-top-left-radius: 5px
  border-bottom-right-radius: 5px
  background-color: white
  display: block!important

.personLists:hover
  background-color: white!important

.add-item-to-list-popover
  border: thin solid QuorumGrey5
  width: 250px
  height: 100px
  position: relative
  top: -60px
  right: 270px
  z-index: 1010
  background: white
  border-radius: 5px

.add-item-to-list-popover-inline
  margin-top: -1px
  margin-right: 1px
  margin-left: 1px

.add-to-list-text,
.add-to-searches-text
  font-weight: 500!important

.top-add-to-list-box,
.bottom-add-to-list-box,
.top-add-to-list-box:hover,
.bottom-add-to-list-box:hover
  height: 50px
  border-bottom: thin solid QuorumGrey5
  background-color: white!important

.bottom-add-to-list-box > .input-group:hover
  background-color: white!important

.add-selected-box
  /*border: 1px solid QuorumGrey5;
  border-bottom: 1px solid QuorumGrey1!important;
  border-top-right-radius:5px;
  border-top-left-radius:5px;*/
  background: white!important
  width: 250px
  height: 100px
  position: relative
  /*bottom: 10px;*/
  /*left: calc(50% - 125px);*/
  /*display:none;*/
  font-weight: 500

.add-searches-box
  /*border: 1px solid QuorumGrey5;
  border-bottom: 1px solid QuorumGrey1!important;
  border-top-right-radius:5px;
  border-top-left-radius:5px;*/
  background: white!important
  width: 250px
  height: 100px
  position: relative
  /*bottom: 10px;*/
  /*left: calc(50% - 125px);*/
  /*display:none;*/
  font-weight: 500

.add-selected-tab:hover,
.add-searches-tab:hover,
.isUp
  color: white
  background: QuorumBlue

.new-button-inline
  height: 32px!important
  width: 34px!important
  background-color: QuorumBlue!important
  border: 1px solid QuorumGrey5!important
  margin-top: 8px
  margin-left: 22px

.create-new-list-icon-inline
  position: relative
  top: -6px
  left: -9px

.stat-list
  overflow-x: hidden

.add-list-text
  position: absolute
  top: 3px
  left: 7px

.select-a-person-list-inline > .select2-choice
  height: 30px!important

.add-new-list,
.add-new-list:hover
  background-color: QuorumBlue!important
  height: 30px!important
  position: absolute!important
  top: 60px!important
  width: 100px!important
  left: calc(50% - 50px)!important

.add-new-list-inline
  background-color: QuorumBlue!important
  height: 36px!important
  margin-left: 25px
  margin-top: 6px

.add-person-list-name-inline
  height: 30px!important
  width: 200px
  border-radius: 5px!important
  border: 1px solid QuorumGrey5!important
  background-color: white!important
  margin-top: 10px
  margin-left: 22px

.add-list-text-inline
  position: relative
  top: -4px

.add-list-text.special
  background-color: QuorumBlue!important

.select-a-person-list,
.select-a-list,
.select-a-bill-list,
.select-a-vote-list,
.select-a-amendment-list,
.select-a-pressrelease-list,
.select-a-statement-list,
.select-a-staffer-list,
.select-a-tweet-list
  width: 180px!important
  height: 26px!important
  min-width: 180px!important
  position: absolute!important
  top: 12px
  left: 10px

.annoying-select2

.select-a-person-list-inline
  /*width: 200px!important;*/
  height: 30px!important
  /*min-width: 180px!important;*/
  margin-top: 10px!important

/*.select2-chosen, .select2-chosen:hover {
  background-color:#fff!important;
}*/
.new-button
  left: 204px !important

.add-list-button,
.add-list-button:hover,
.add-search-button,
.add-search-button:hover
  height: 26px!important
  position: relative!important
  top: 12px!important
  width: 30px!important
  background-color: QuorumBlue!important
  border: 1px solid QuorumGrey5!important
  float: left
  left: 203px
  margin-right: 33px

.add-list-button-inline
  height: 32px!important
  /*width: 37px!important;*/
  background-color: QuorumBlue!important
  border: 1px solid QuorumGrey5!important
  margin-top: 10px
  margin-left: 5px

.create-list-icon,
.create-list-icon:hover
  position: absolute
  top: 5px
  left: 5px
  background-color: QuorumBlue!important

.create-list-icon-inline
  position: relative
  top: -7px
  left: -9px
  background-color: QuorumBlue!important

.add-person-list-name,
.add-bill-list-name,
.add-vote-list-name,
.add-amendment-list-name,
.add-pressrelease-list-name,
.add-statement-list-name,
.add-tweet-list-name,
.add-staffer-list-name,
.add-person-search-name,
.add-bill-search-name,
.add-vote-search-name,
.add-amendment-search-name,
.add-committee-search-name,
.add-pressrelease-search-name,
.add-staffer-search-name,
.add-statement-search-name,
.add-tweet-search-name,
.add-caucus-search-name,
.add-trip-search-name,
.add-committeedoc-search-name,
.add-schedule-search-name,
.add-comment-search-name,
.add-dcletter-search-name,
.add-fbpost-search-name
  height: 26px!important
  width: 163px!important
  position: absolute!important
  top: 10px!important
  border-radius: 5px!important
  left: calc(50% - 19px)
  border: 1px solid QuorumGrey5!important
  background-color: white!important

.add-person-list-name:hover,
.add-bill-list-name:hover,
.add-vote-list-name:hover,
.add-amendment-list-name:hover,
.add-pressrelease-list-name:hover,
.add-statement-list-name:hover,
.add-tweet-list-name:hover,
.add-staffer-list-name:hover
  background-color: white!important

.add-item-to-list-popover-2
  -moz-box-shadow: 0 0 4px QuorumGrey5
  -webkit-box-shadow: 0 0 4px QuorumGrey5
  box-shadow: 0 0 4px QuorumGrey5
  border: 1px solid QuorumGrey5
  width: 250px
  height: 100px
  position: relative
  top: 155px
  z-index: 1010
  right: 40px
  background: white
  border-radius: 5px

.add-list-button.new-button.billlists2
  height: 26px!important
  position: relative!important
  left: 21px!important
  top: -60px!important
  width: 30px!important
  background-color: QuorumBlue
  border: 1px solid QuorumGrey5
  float: left
  left: 203px
  margin-right: 33px

/* HIGHLIGHTED */
.highlighted, mark
  font-weight: 900!important
  font-size: 1.1em!important
  color white
  background-color QuorumBlue
  padding 2px 4px

.select2-drop
  z-index: 5001!important

.select2-drop-mask
  z-index: 4000!important

.datepicker
  z-index: 1800!important

.modal-backdrop
  z-index: 5009

.modal
  z-index: 5010

.member-box .thumbnail
  height: 300px
  overflow-y: hidden

.member-box :hover
  background-color: #eee
  border-color: QuorumGrey5

.member-box .caption,
.member-box .caption :hover
  background: none

.member-box .thumbnail .caption,
.member-box .thumbnail .caption h3
  margin-top: 4px

.member-box a
  text-decoration: none

.republican
  border: 2px solid RepublicanMainColor
  border-color: RepublicanMainColor!important

.democrat
  border: 2px solid DemocratMainColor
  border-color: DemocratMainColor!important

.libertarian
  border: 2px solid QuorumGrey2
  border-color: QuorumGrey2!important

.independent
  border: 2px solid IndependentColor
  border-color: IndependentColor!important

.defaultPartyClass
  border: 2px solid #ccc
  border-color: #ccc!important

// Puerto Rico colors
.new-progressive-party
  border: 2px solid PNPMainColor
  border-color: PNPMainColor!important

.popular-democratic-party
  border: 2px solid PPDMainColor
  border-color: PPDMainColor!important

.puerto-rican-independence-party
  border: 2px solid PIPMainColor
  border-color: PIPMainColor!important

.working-peoples-party
  border: 2px solid PPTMainColor
  border-color: PPTMainColor!important

// EU colors
.european-peoples-party
  border: 2px solid EPPMainColor
  border-color: EPPMainColor!important

.socialists-and-democrats
  border: 2px solid SDMainColor
  border-color: SDMainColor!important

.european-conservatives-and-reformists
  border: 2px solid ECRMainColor
  border-color: ECRMainColor!important

.alliance-of-liberals-and-democrats
  border: 2px solid ALDEMainColor
  border-color: ALDEMainColor!important

.european-united-left-nordic-green_left
  border: 2px solid GUENGLMainColor
  border-color: GUENGLMainColor!important

.greens-european-free-alliance
  border: 2px solid GreensEFAMainColor
  border-color: GreensEFAMainColor!important

.europe-of-free-and-direct-democracy
  border: 2px solid EFDDMainColor
  border-color: EFDDMainColor!important

.europe-of-nations-and-freedom
  border: 2px solid ENLMainColor
  border-color: ENLMainColor!important

.non-inscrits
  border: 2px solid NIMainColor
  border-color: NIMainColor!important

.patriots-for-europe
  border: 2px solid PFEMainColor
  border-color: PFEMainColor!important

.europe-of-sovereign-nations
  border: 2px solid ESNMainColor
  border-color: ESNMainColor!important

.highlight:hover .add-item-to-list-popover,
.top-add-to-list-box
  background-color: white!important

.thumbnail:hover + .btn-select-person-container
  background-color: white
  border: 1px solid QuorumGrey5!important

.panel:hover > .btn-select-bill-container,
.panel:hover > .btn-select-vote-container,
.panel:hover > .btn-select-amendment-container,
.panel:hover > .btn-select-press-container,
.panel:hover > .btn-select-statement-container
  background-color: white
  border: 1px solid QuorumGrey5!important

.panel:hover > .btn-select-vote-container
  background-color: white
  border: 1px solid QuorumGrey5!important

.panel:hover > .btn-select-amendment-container
  background-color: white
  border: 1px solid QuorumGrey5!important

/* fixes annoying one pixel white space on stat list headers */
.stat-list-header
  background-color: QuorumBlue

.select-result
  display: block!important
  height: 25px!important

.added-result
  display: block!important
  height: 25px!important
  top: -25px
  position: relative

.pager li
  cursor: pointer

/* page tutorial styling */
.introjs-tooltiptext
  font-size: 16px

.introjs-helperNumberLayer
  padding-top: 0px!important
  line-height: 15px!important

#vis-tooltip
  z-index: 12000!important

.select2-search-choice-close
  margin-top: -2px!important

.notif-seen li:hover
  /*background-color: #EDEFF5;*/
  background: #F6F7F8

.notif-unseen
  background-color: #D5D7DC

.notif-unseen li:hover
  background: #F6F7F8

.notif-item
  text-overflow: ellipsis
  overflow: hidden
  width: 100%
  display: block

.dropdown-notifs
  width: 500px
  max-height: 700px
  min-height: 80px
  overflow-y: auto

.dropdown-notifs > li
  padding: 10px
  list-style-position: inside
  /*border-top: 1px solid #c4c4c4;*/
  border-bottom: 1px solid #c4c4c4

.dropdown-notifs > li > div > a
  display: inline
  padding: 3px 0px !important
  text-overflow: ellipsis
  overflow: hidden
  width: 100%

.dropdown-notifs > li > a:hover,
.dropdown-notifs > li > a:focus
  background-color: #f5f5f5!important

.dropdown-notifs > li:hover
  background-color: #f5f5f5

.alert-popup
  height: inherit
  width: 300px
  top: 55px
  right: 10px
  z-index: 9999
  position: absolute

.alert-list
  list-style-type: none

.single-alert-popup
  background-color: aquamarine
  /*position: fixed;*/
  padding-left: 15px
  padding-top: 10px
  height: 80px
  margin-bottom: 10px

.popup-div
  margin-left: 31px
  margin-top: -21px

.data-download
  float: right
  cursor: pointer
  font-size: 8pt
  position: absolute!important
  top: 39px
  left: 10px
  color: #fff

.data-save
  float: right
  cursor: pointer
  font-size: 8pt
  position: absolute!important
  top: 39px
  right: 10px

.data-save:hover .fa-circle
  color: #E6E6E6!important

.data-download:hover .fa-circle
  color: #E6E6E6!important

.qs-exit
  background-color: white
  color: QuorumBlue
  position: fixed
  right: 0px
  padding: 4px 8px 4px 8px
  border: none
  border-left: 1px #ddd solid
  border-bottom: 1px #ddd solid
  border-bottom-left-radius: 5px
  border-color: QuorumGrey5
  z-index 1050
  &.smallestHeader
      margin-top 0px
  &.smallHeader
      margin-top -10px

.qs-exit:hover
  background-color: PrivateGrey !important
  cursor: pointer

.qs.mini-box
  margin-top: 29px

.qs.stat-list-item
  font-size: 1.2em
  overflow: hidden
  white-space: nowrap
  display: inline-block
  width: 80%
  padding-top: 8px
  margin-left: 8px
  position: absolute

.qs-members-display .qs.stat-list-item
  padding-right: 25px

.qs.img-circle
  width: 35px
  height: 35px!important
  margin-top: 1px!important

.qs-category-row
  text-align: center
  border-bottom: 2px dashed
  color: #3d3262
  padding-top: 30px
  padding-bottom: 10px

.qs-title > .fa
  margin-right: 5px

.qs-see-all
  float: right
  margin-top: -60px
  font-size: 32px

.qs-see-all:hover
  cursor: pointer

.qs-display
  width: calc(100% - 40px)

.qs.mini-box > .list-group-item
  height: 57px
  margin-top: 1px
  border-radius: 5px!important

.qs.btn-select-person-container.xsmall
  height: 30px!important
  width: 30px!important
  top: 28px!important

.qs .btn-select-person
  margin-top: 0px!important
  margin-left: 1px!important

.qs-display-container
  padding-left: 180px
  padding-bottom: 30px
  min-height: 79px

.qs-title .fa-check
  font-size: 20px!important

.qs-display-full
  min-height: 288px!important

.qs-updown
  position: absolute
  right: 54px
  font-size: 3em
  padding-top: 70px
  color: #3d3262

.qs-up
  position: absolute

.qs-up:hover
  cursor: pointer

.qs-down
  position: absolute
  margin-top: 85px

.qs-down:hover
  cursor: pointer

.qs-up:focus,
.qs-down:focus
  outline: none

.qs-page-display
  font-size: 16px
  position: absolute
  margin-top: 35px
  margin-left: 21px

.qs-of-page
  font-weight: bold
  margin-left: -3px

.qs-adjust
  margin-left: -5px

.qs-disabled
  color: #8B84A1!important

.qs-disabled:hover
  cursor: default!important

.qs-not-current
  background: rgb(228, 228, 228)!important

.qs-not-current:hover
  background: #d3d3d3!important
  cursor: pointer

.hide-scrollbars::-webkit-scrollbar
  display: none
  width: 0px

.mini-box .select-person,
.mini-box .select-person:hover
  margin-left: 6px
  margin-top: 8px
  color: QuorumBlue

.mini-box .btn-select-person
  vertical-align: 2px

.qs-section
  margin-left: -15px
  margin-right: -15px
  padding-left: 15px
  padding-right: 15px

.qs-visible:nth-child(even):not(.qs-light),
.qs-dark
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), QuorumBlue

.qs-visible:nth-child(odd):not(.qs-dark),
.qs-light
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #bbb

.qs-title
  color: QuorumBlue
  margin-top: 25px
  position: absolute
  font-size: 22px
  font-weight: 300

.qs.mini-box a
  border-color: #aaa

.qs.mini-box .btn-select-person-container,
.qs.mini-box .btn-select-person-container:hover
  border-color: #aaa!important

.qs-noresults-text,
.qs-loading-text
  font-size: 20px
  margin-top: 26px
  position: absolute
  color: QuorumBlue
  margin-left: 15px

.qs-pressreleases-section.qs-noresults .qs-title,
.qs-statements-section.qs-noresults .qs-title,
.qs-committeedocs-section.qs-noresults .qs-title,
.qs-schedules-section.qs-noresults .qs-title,
.qs-dcletters-section.qs-noresults .qs-title
  margin-top: 12px

.qs-noresults #qs-dcletters
  display: none

.staffer-result
  background-image: url('/static/img/seal.png')
  background-size: 19%
  background-repeat: no-repeat
  background-position: 10px 10px

.note-editor .modal-dialog
  min-width: inherit
  width: 540px!important
  min-height: 0px!important
  text-align: left
  top: 70px

.note-editor .modal-content
  padding-top: 0px
  box-shadow: rgba(0, 0, 0, 0.498039) 0px 5px 15px 0px

.note-editor.fullscreen
  position: fixed
  top: 59px!important
  left: 59px!important
  z-index: 1050
  width: calc(100% - 59px)!important
  border-radius: 0px

#toggle_all
  height: 59px
  font-size: 16px
  font-weight: 300
  -webkit-font-smoothing: subpixel-antialiased
  margin-top: 0px!important

.bill-part
  /*border-left: 1px solid QuorumGrey1;*/
  margin: 10px 0px 10px 10px
  padding-left: 5px

.section-header
  height: 29px
  width: 100%
  /*background-color: QuorumGrey1;*/

span.editable-container.editable-inline
  margin-top: -8px!important

/* Scrollbar information */
.win ::-webkit-scrollbar
  width: 14px
  height: 14px
  background-color: rgba(0,0,0,0)
  -webkit-border-radius: 100px

.win body::-webkit-scrollbar
  -webkit-border-radius: 0px

/* hover effect for both scrollbar area, and scrollbar 'thumb' */
.win ::-webkit-scrollbar:hover
  background-color: rgba(0, 0, 0, 0.09)

.win ::-webkit-scrollbar-corner,
::-webkit-resizer
  display: none

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
.win ::-webkit-scrollbar-thumb
  background: #c3c3c3
  -webkit-border-radius: 100px
  background-clip: padding-box
  border: 3px solid rgba(0, 0, 0, 0)
  min-height: 30px

.win ::-webkit-scrollbar:hover ::-webkit-scollbar-thumb,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active
  background: #7e7e7e
  /* Some darker color when you click it */
  -webkit-border-radius: 100px
  border: 3px solid rgba(0, 0, 0, 0)
  background-clip: padding-box

.ui-tooltip-content
  font-weight: 400

.page-wrapper.permission-denied
   .title-line
       margin-bottom: 35px;
       font-weight: 300;
       color: #3d3262 ;
       text-align: center;
       border-bottom: 2px #3d3262  dashed;
       margin-left: 70px;
       margin-right: 70px;
       padding-bottom: 10px;
       margin-top: 2rem;
       font-size: 3.2em;
       font-weight: 400;

   .left-col
       text-align: center;
       max-height: calc(100vh - 169px);
       overflow-y: scroll;
       padding-bottom: 50px;

   #submit-button
       font-size: 25px;
       margin-top: 20px;
       width: auto;

   .region
       font-style:italic;
       color:#3d3262 ;

   .img-seal
       width: 65%;
       margin-left: 20%;

// special EU styling for top bar
.dropdown-menu > li > a
    .eu-icon-dropdown
        position relative

        svg
            position absolute
            top -3px
            left 0px

            .eu-star
                fill black

    &:hover
        .eu-icon-dropdown
            svg
                .eu-star
                    fill white

.eu-icon-topmenu-old
    display inline-block
    position relative
    width 31px

    svg
        position absolute
        top -23px
        left -3px

.eu-icon-topmenu-old
    .eu-star
        fill white

.eu-icon-topmenu
    padding-top 4px

.tooltip-inner
    white-space pre-line !important
    max-width 500px
    max-height 50vh

.use-line-breaks
    white-space pre-line

.tooltip
  font-size 14px

// Public Spreadsheet styles
.nav-bar-logo
    width: 400px
    height: 40px
    background-size: contain
    background-repeat: no-repeat
    margin-left: 15px
    margin-top: 10px

.sheet-name-public
    position: absolute
    top: 0
    right: 0
    text-align right
    margin-right: 15px
    color: TopBarTextColor
    font-size: 34px
    font-weight: 100

// this class should be used as the parent for all flexbox containing elements
.flexcontainer
  display -webkit-flex
  display flex
  -webkit-flex-direction row
  flex-direction row

/* weird text added by jquery should be hidden */
.ui-helper-hidden-accessible
  display none


.quorum-date-range-picker
  .date-picker-button
    margin-bottom 10px
  .DateRangePickerInput__showClearDates
    border-radius 3px
    // calendar styles
  .CalendarDay__selected
      border 1px solid QuorumBlue
      background QuorumBlue
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover
      background-color QuorumBlue
      border 1px solid QuorumBlue
      background QuorumBlue

  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover
      background SelectBorderGrey
      border 1px solid QuorumBlue
      color BodyTextColor

  .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover
      border 1px solid QuorumBlue
      background #7a94d2
      color BodyTextColor

  .CalendarDay__selected_span
      background #7a94d2
      border 1px solid QuorumBlue

  .DateInput_input__focused
      border-bottom 2px solid QuorumBlue

.bootstrap-datetimepicker-widget
    width: calc(100% - 12px)
    .table-condensed
        width: 100%
