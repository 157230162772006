@import 'DESIGN.styl'

.loading
    height 100%
    background-color white
    color gray
    flex-grow 1
    display flex

#project-spinner, #board-spinner
    z-index 999 !important


.loading:not(#map-loading):not(#profile-stats-loading) > div
    display flex
    justify-content center
    align-items center
    flex-grow 1

.loading > div > span
    display table-cell
    text-align center
    vertical-align middle
    background-image url('../img/logos/quorum-q-new.png')
    background-size 70%
    background-repeat no-repeat
    background-position 50% 51%
    width 200px
    height 200px
    position absolute

.loading-new.loading > div > span
    display table-cell
    text-align center
    vertical-align middle
    background-image url('../img/new_logo_spinner.gif')
    background-size 70%
    background-repeat no-repeat
    background-position 50% 51%
    width 300px
    height 300px
    position absolute

#map-loading > div > span
    position absolute
    left 45%
    top 35%

.loading i
    font-size 20em

.loading-spinners
    all initial
    position absolute

#page-loading, #spinner
    top -150px
    left -15px
    height calc(100vh - 60px)
    width calc(100% + 15px)
    z-index 4500
    border-left 1px solid #e7e7e7

#page-loading-no-top,
    left -15px
    width calc(100% + 15px)
    z-index 4500
    border-left 1px solid #e7e7e7

#external-loading
    padding-top 45vh

#tab-loading
    width calc(100% - 59px)
    left 59px
    height 115%
    display none
    z-index 4000

#map-loading
    position absolute
    width 100%
    background-color rgba(0, 0, 0, .4)

#map-loading-spinner
    align-items center
    justify-content center

#search-viz-loading
    display flex
    flex auto
    width 100%
    min-height inherit
    justify-content center
    align-items center
    background-color InlineHoverColor
    position absolute
    z-index 1000

#search-page-loading
    display flex
    top 58px
    height 100%

#dashboard-modal-loader
    top 30vh

#form-table-loading
    height 250px
    width calc(100% + 15px)
    z-index 4500
    margin-bottom 15px
