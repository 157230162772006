@import DESIGN

.form-input-group-field
    &-option
        display block;
        font-weight: 400 !important;

        input
            margin-right 1rem !important;

    &.new-action-center__radio-button-group
        .form-input-group-field-option
            display: inline-block
            font-size: 14px
            position: relative
            margin-left: 17px
            &:not(:first-child)
                margin-left: 29px
            input[type="radio"]
                position: absolute
                top: 50%
                transform: translate3d(calc(-100% + -4px), -50%, 0);
                display: inline-block
                vertical-align: middle
                margin-top: 0
                margin-right: 4px !important;
