@import DESIGN

.form-people-selector-field
    .form-people-selector-container
        margin-top 5px
        margin-bottom 15px

    label
        font-size ReduxFormFontSize

        &:focus
            ErrorFocus()

    .people-selector-warning-outline
        border-color ReduxFormWarningColor

        &:focus
            WarningFocus()

    .form-people-selector-header
        display flex
        justify-content space-between
        width 100%

    .form-people-selector-error
        color ReduxFormErrorColor

    .form-people-selector-warning
        color ReduxFormWarningColor

    .form-people-selector-header
        display flex
        justify-content space-between
        align-items center
