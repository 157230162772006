@import "DESIGN.styl"

// For styling across many redux forms

.form-label
    font-size FormLabelSize
    font-weight normal

.control-label .required {
   color:red;
}

.new-action-center
  // typography
  h1
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%
  h5
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
  p
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;

  label.control-label
      color: #212529;
      font-family: 'Helvetica Neue', SANS-SERIF;
      font-size: 14px;
      font-weight: 500;

  // inputs
  select, input[type=text]
      display: flex;
      padding: var(--sm, 12px);
      align-items: flex-start;
      gap: var(--xs, 8px);
      align-self: stretch;
      border-radius: var(--xxs, 4px);
      border: 1px solid var(--Gray-5, #A9B5BF);
      background: var(--White, #FFF);
  input[type=checkbox]
      display: flex;
      width: 16px;
      height: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  input[type=button], input[type=submit]
      display: flex;
      height: 40px;
      padding: 8px 32px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 32px;
  input[type=radio]
      display: flex;
      align-items: center;
      gap: 8px;

  .control-label, .form-label, label.control-label, label.form-label
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px !important

  &__button-wrapper
      display: flex;
      justify-content: center;

  &--pac-charitable-match
      label.control-label
        font-size: 14px
        color: #495057

  &__button
      width: auto !important;
      padding: 8px 32px !important;
      border-radius: 32px !important;
      font-size: 14px !important;
      outline: none !important;
      border: none !important;

  .form-input-field,
  .form-gridtoggle-field,
  .form-input-group-field,
  .form-checkbox-field,
  .form-select-field,
  .new-action-center__section,
  .new-action-center--pac-charitable-match,
  .new-action-center__button-wrapper
    padding-top: 8px !important
    padding-bottom: 8px !important
    margin-top: 0 !important
    margin-bottom: 0 !important

  .form-checkbox-field .btn
    padding-top: 0 !important
    padding-bottom: 0 !important

  .form-gridtoggle-container
    margin-bottom: 0 !important

  .registration-pre-text
    margin-bottom: 8px !important

  .new-action-center--pac-charitable-match
    .form-label
        margin-bottom: 4px !important
    .redux-form-field:last-child
        padding-bottom: 0 !important
        padding-top: 0 !important

  .new-action-center__terms-and-conditions
    .form-label
      margin-bottom: 8px !important
    .disclaimer
      margin-top: 0 !important
      padding-bottom: 8px !important
    .redux-form-field:last-child
        padding-bottom: 0 !important

  .form-input-group-field-option
    input[type="radio"]
        margin-right: 8px !important
