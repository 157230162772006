@import "STYLUS_FUNCTIONS.styl"
@import "SEARCH_VARIABLES.styl"

Ellipsis()
    display block
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

//  truncates text with ellipses at x lines
XLineEllipsisNopad(x)
    -webkit-line-clamp x
    -webkit-box-orient vertical
    text-overflow ellipsis
    line-height normal

XLineEllipsis(x, padleft, padright)
    display -webkit-box
    -webkit-line-clamp x
    -webkit-box-orient vertical
    overflow hidden
    text-overflow ellipsis
    -o-text-overflow ellipsis
    line-height normal
    padding-left padleft
    padding-right padright

.infinite-scroll-loading-more
    color Gray7
    font-size 1rem
    font-weight 500
    text-align center
    height 40px
    // ensure that the "Loading More"/"No More Results" text is always on the next line
    flex-basis 100%
    margin 2rem 0

.shared-inline
    // remove all bootstrap padding
    .col-md-9, .col-lg-9
        padding 0
    &
        margin-bottom InlineMargin
        horizontalPadding(HalfHorizontalMargin)
        width 100%
        position relative

    &.half-width
        width 50%

    //  make options individually highlight on hover
    &:hover
        .inline-options-wrapper
            border-color BorderHighlightColor
            .inline-option
                border-color BorderHighlightColor

    .first-line
        font-size 22px
        color QuorumBlue
        margin-top 15px
        margin-bottom 0
        XLineEllipsis(2, 0px, 5px)
        max-height 55px

    h3
        font-size SubtextFontSize
        line-height 23px
        margin-top 8px
        margin-bottom 0
        color InlineGrey
        Ellipsis()

    a
        text-decoration none

    .text-section
        border thin solid LightBorderColor
        border-radius 4px
        background-color white
        padding 5px

    .dropdown-options
        position absolute
        right -5px
        z-index 1000
        margin-top -1px
        width 33.33%
        horizontalPadding(15px)

        .shared-inline-dropdown
            box-shadow 0 6px 12px rgba(0, 0, 0, 0.175)
            border-bottom-right-radius 4px
            border-bottom-left-radius 4px

            .quorum-tag.loading, .list-group-item.stance-loading
                text-align center

                h4
                    margin 0 auto

                &:hover
                    background-color White

        #drop-toggle-id
            margin-top 0px

        .panel-heading
            .fa-chevron-down
                display none

        .filter-group
            border-top-left-radius 0px
            border-top-right-radius 0px

            i
                #info-click
                    &:active
                        pointer-events none

        .panel-heading.active
            background-color QuorumGrey6
            border-bottom thin solid LightBorderColor
            text-align center
            height 41px

            h4
                font-weight 500
                font-size 17px
                color StandardGrey
                line-height 0

        .btn-group.dropdown
            width 100%

            .dropdown-button-maintain
                width 100%
                padding 10px
                max-width 100%
                border-radius 0px 0px 4px 4px
                display flex
                flex-direction row
                justify-content space-between
                align-items center
                border 0px
                border-top thin solid QuorumGrey5

            #dropdown-menu-selector
                border-top 0px
                margin 0px
                width inherit

                .list-label
                    margin-top -4px
                    margin-bottom -5px
                    color QuorumGrey2

                .board-label
                    margin-top -4px
                    margin-bottom -5px
                    color QuorumGrey2

                .proj-label
                    margin-top -5px
                    color QuorumGrey2

                .list-grouping-title.any-item.search-proj-list
                    border-bottom thin solid QuorumBlue
                    font-weight 500
                    background-color LightTransparentGrey
                    text-align center
                    padding 4px 20px 0px
                    margin-left -10px
                    font-size 17px
                    color StandardGrey

                .board-grouping
                    border-bottom thin dashed QuorumGrey2
                    font-weight 500
                    text-align center
                    background-color LightTransparentGrey
                    padding 4px 20px 20px
                    margin-left -10px
                    color StandardGrey
                    height 25px

                a > li.list-item
                    background-color white
                    border-bottom thin solid QuorumGrey5
                    cursor pointer
                    padding 4px 20px 8px
                    margin-left -10px

                    .list-text
                        color QuorumBlue

        .stat-list-container
            margin-bottom 0px
            border-top-left-radius 0px
            border-top-right-radius 0px
            border 0px

            .quorum-tag
                border-bottom thin solid LightBorderColor

            .dropdown-button-maintain
                border 0px

            .list-group-title
                border-top 0px
                border-left 0px
                border-right 0px
                border-bottom thin solid LightBorderColor

            .quorum-selected-objects
                border-top thin solid LightBorderColor

        .filter-subgroup-title
            i
                padding-top 4px

            i.fa-check-square-o
                right 13px

    .url-icon-wrapper, .person-profile-img.url-image
        margin-right 15px
        margin-bottom 15px
        margin-top 15px

        .url-image
            max-width 100px

    .person-profile-img.url-image
        border-radius 50%
        max-width 100px

    .icon-wrapper
        border 3px solid QuorumGrey3
        padding 15px
        border-radius 100%
        margin-right 15px
        margin-bottom 15px
        margin-top 15px

        &.state-icon
            min-width 115px
            height 115px
            text-align center

    .fa5-inline-icon
        color Violet5
        width auto
        height 1.25rem
        display flex
        justify-content center
        align-items center
        font-size 1.25rem

    // Removes the border of .icon-wrapper
    .naked-icon-wrapper
        border 0

    .inline-icon
        font-size 1.25rem
        height 1.25rem
        width auto
        color Violet5

        &.inline-icon-shrink
            font-size 1.25rem

        // fix fontawesome icons
        &.stateface
            line-height 65px

        &.half-width-icon
            font-size 25px
            height 25px
            width 25px

        &.inline-icon-shrink
            font-size 44px

    // Specific custom icon styles:
    .youtube-icon
        color YouTubeAPIRed

    // colors for Vote Inline Icon status
    .yea
        border-color VotePassedColor
        color VotePassedColor

    .nay
        border-color VoteFailedColor
        color VoteFailedColor

    .present
        border-color VotePresentColor
        color VotePresentColor

    .missed
        border-color VoteMissedColor
        color VoteMissedColor

    .unknown
        border-color VoteUnknownColor
        color VoteUnknownColor

    .inline-options-wrapper
        right 10px
        z-index 1
        position absolute
        bottom 0px
        overflow hidden
        width auto
        border-top-left-radius BorderRadius
        border-bottom-right-radius BorderRadius
        border thin solid BorderColor

        &.is-searchify
            &.is-searchify-selected
                right 11px

        .inline-option
            display inline-flex
            position relative
            padding 4px
            color QuorumBlue
            border-left thin solid BorderColor
            background-color alpha(PanelBackgroundColor, 0.75)

            .inline-clear-text
                margin-top 2px

            &:hover
                background-color alpha(OptionHighlightColor, 0.75)

            &:active
                background-color alpha(BorderHighlightColor, 0.75)

            &:first-child
                border-left none

            a
                display inline-block
                position absolute
                width 100%
                height 100%
                top 0px
                left 0px

            a:hover
                cursor pointer

            i
                color QuorumBlue
                width InlineOptionSize
                height InlineOptionSize
                line-height InlineOptionSize
                font-size "calc(%s * 0.8)" % (InlineOptionSize)
                text-align center


    .inline
        overflow-x visible
        position relative
        background-color white
        border 1px solid Gray2
        border-radius BorderRadius
        box-shadow 0px 2px 4px -2px rgba(0, 0, 0, 0.12)
        padding-left 15px

        > div
            display flex
            align-items flex-start
            box-shadow none

        > a
            display flex
            align-items flex-start
            height 100%
            width 100%

            a
                &:hover
                    border-bottom Black thin dotted

        input[type="file"]
            display none

        &:hover:not(.inline-nosegue)
            background-color Violet0 !important
            border-color Violet1
            box-shadow 0 4px 12px -4px rgba(0, 0, 0, .2)
            cursor pointer

            .text-section
                background-color lighten(PanelHighlightColor, 30%)

        .inline-text
            width 100%
            overflow hidden

            & > :last-child
                margin-bottom 18px

            // Specifically for YouTube API compliance:
            .youtube-third-line
                margin-bottom 35px

            h3:first-child
                margin-top 15px

            h3:last-child
                margin-bottom 15px

            .title-hoverscroll
                display inline-block

            .link-controls
                // disable text selection as we are using a div as a button
                // currently supported by Chrome and Opera
                user-select none
                // Firefox
                -moz-user-select none
                // Safari
                -webkit-user-select none
                // iOS Safari
                -webkit-touch-callout none
                // Konqueror HTML
                -khtml-user-select none
                // Internet Explorer/Edge
                -ms-user-select none
                position absolute
                right LinkControlsRightDistance
                margin-top -25px

                // shared styles
                .see-text-control, .expand-control
                    font-size SubtextFontSize
                    &.related-event
                        padding-left 10px
                .expand-control
                    &:hover
                        cursor pointer
                        color LinkHighlightColor
                        border-bottom none
                    i
                        font-size SubtextFontSize * 2
                        position absolute
                        left -28px
                        bottom -5px
                .has-pdf
                    &:hover
                        border-bottom Black thin dotted

            .inline-bill, .inline-bills, .inline-organizations, .inline-feed-item
                display flex
                flex-wrap wrap
                margin-top 8px

                .label
                    white-space normal
                    text-align left
                    XLineEllipsisNopad(3)
                    margin-right 5px
                    display flex
                    margin-top 5px
                    font-size 14px
                    padding 5px
                    overflow hidden

                    &.label-hyperlink
                        background-color HyperlinkColor
                        b
                            object
                                a
                                    color White
            .alert-feed
                margin-top 10px

                .inline-feed-item-table
                    margin-top 5px

                    .inline-feed-item-table-row
                        color Black

                        td
                            &:not(:last-child)
                                padding-right 20px
                                white-space nowrap
                                vertical-align top

                            &:last-child
                                width 100%

                            border-width thin 0px 0px
                            border-style dotted solid solid
                            border-color FeedItemBorderColor transparent
                            padding 4px 0px

                            b
                                color QuorumBlue
                                font-size 14px
                .text-section
                    margin-right 1em
                    margin-bottom 2px

        &.is-searchify
            &.inline
                a
                    .inline-text
                        .bill-status-timeline
                            .bill-status-timeline-inner
                                width initial

        &.is-searchify-selected
            background-color SearchifyColor
            border 2px solid QuorumBlue
            border-radius BorderRadius
            box-shadow 5px 5px 15px QuorumGrey4
            padding-left 14px

            // prevent the inner content of the Inline shifting when we add a 2px border
            &.inline
                a
                    .icon-wrapper
                        margin-top 14px
                    .inline-text
                        h2
                            margin-top 14px
                        .link-controls
                            right 17px
                        .bill-status-timeline
                            .bill-status-timeline-inner
                                // make sure the timeline does not move around
                                // due to the extra selection border
                                margin-right 29px

            &.cardholder
                    padding 7px

            &:hover
                border 2px solid QuorumBlue
                background-color SearchifyDocumentHoverColor
                box-shadow 5px 5px 15px QuorumGrey4

        // // uncomment for ~depth~
        // &:active
        //     box-shadow none
        //     margin-top 2px
        //     margin-left 2px
        //     margin-right -2px
        //     margin-bottom -2px
        //     ~ .inline-options-wrapper
        //         right 8px
        //         bottom -2px

    .inline-noclick
        box-shadow none

    .region
        color HyperlinkColor

    .description
        display block
        color BodyTextColor
        margin-top 10px
        margin-right 1em
        font-size BodyTextSize
        max-height 130px
        overflow hidden

        .alert-snippets
            .alert-snippet-item
                b
                    color QuorumBlue
                    font-size 14px
                .snippets
                    font-size 13.5px

        .visit-website
            margin 0

        // images from medium and instagram
        .graf-image, .instagram-image
            background-color White
            border thin solid QuorumGrey1
            padding 14px
            border-radius 4px
            margin-top 10px
            min-height 200px

        // medium posts
        figcaption
            width 50%
            margin-left 20%
            margin-top 7px
            font-size 12px
            font-style italic

        // medium posts
        figure
            margin-top 20px
            margin-bottom 13px

        // all other images
        img
            max-width 50%
            horizontalMargin(20%)

        h3
            margin-top 25px
            color StandardGrey

        h4
            margin-top 25px
            color StandardGrey

        video
            background-color White
            border thin solid QuorumGrey1
            padding 14px
            border-radius 4px
            width 50%
            max-width 50%
            margin-left 20%
            margin-right 20%
            min-height 200px

    // images in constituent emails that should not be modified
    .external
        a
            pointer-events auto
        pointer-events none

        img
            max-width none
            margin 0
            min-height initial

    // expand the Inline to its actual height
    .expanded
        max-height initial

    // spacing for see more dropdown button
    .seeMore
        width calc(100% - 120px)

    .enacted
        font-weight bold
        border-color HyperlinkColor

    // Inline document pdf
    .pdf-select
        margin-top 10px

    .pdf, .pdf-select
        height 800px
        width 100%
        border 0
        padding-right 15px
        // app/static/frontend/components/DiscreteSelectPDFWrapper
        // sets both the wrapper and the PDFViewer to use the same className
        .pdf, .pdf-select
            padding-right 0px

@media only screen and (max-width 750px)
    #regions
        display none

@media only screen and (max-width 700px)
    .filterable-list-view
        .flv-content
            .filter-side-panel
                display none

@media only screen and (max-width 550px)
    .icon-wrapper, .gn-menu-wrapper, #account
        display none
    .main
        padding-left 0
    #qs-results
        &.submittable
            margin-left 0

@media only screen and (max-width 500px)
    .shared-inline
        .seeMore
            width initial
    .shared-inline
        padding-right 0px
    .link-controls, .icon-wrapper, #interaction
        display none

@media only screen and (max-width 450px)
    #help
        display none

@media only screen and (max-width 400px)
    .inline-options-wrapper, #gn-menu > li:not(#search)
        display none
