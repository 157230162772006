@import 'DESIGN.styl'

// styles for sweet alert version 2
.swal-button
    background-color var(--filled-background, QuorumBlue)
    border none
    box-shadow none
    font-size 17px
    font-weight 500
    -webkit-border-radius 4px
    border-radius 5px
    padding 10px 32px
    cursor pointer
    display inline-block
    box-shadow rgba(48, 62, 96, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset
    transition: .3s ease-in-out;
    transition-property: color, background-color;

    &:focus
        background-color var(--filled-background-hover, #566486)

    &:not([disabled])
        &:active, &:hover
            background-color var(--filled-background-hover, lighten(QuorumBlue, 15%))

.swal-text
    text-align center
    font-size 18px

.swal-footer
    text-align center

.swal-button--cancel
    background-color var(--outlined-background, DarkPurple)
    color var(--outlined-color, white)

    &:focus
        background-color var(--outlined-background-hover, #635888)
        color var(--outlined-color-hover, white)
    &:not([disabled])
        &:active, &:hover
            background-color var(--outlined-background-hover, lighten(DarkPurple, 15%))
            color var(--outlined-color-hover, white)

.swal-button--confirm
    color white

.swal-button--loading
    color var(--filled-background, QuorumBlue) !important // zero out the color

.swal-icon--warning
    span
        background-color #735788

.swal-icon--warning__body, .swal-icon--warning__dot
    background-color #63447a

.swal-button--forgotPassword
    background-color Violet2
    color Violet8

    &.swal-button&:hover
        background-color Violet3
        color Violet9
