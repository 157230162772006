// Jake's Variables
transitionTime = .25s

vendor(prop, args)
    -webkit-{prop} args
    -moz-{prop} args
    -ms-{prop} args
    -o-{prop} args
    {prop} args

// Jake's Functions
transition()
    -webkit-transition all transitionTime ease
    -o-transition all transitionTime ease
    -moz-transition all transitionTime ease
    -ms-transition all transitionTime ease
    // -kthtml-transition: all 1s linear;
    transition all transitionTime ease

Rotate(degs)
    vendor(transform, rotate(degs))

Transform()
    vendor(transition-duration, arguments)

    -webkit-transition-property -webkit-transform
    -moz-transition-property -moz-transform
    -o-transition-property -o-transform
    transition-property transform

Grey1 = #ccc
Grey2 = #c3c3c3
Grey3 = #7e7e7e

LoginCard(degs)
    content ""
    position absolute
    width 100%
    height 100%
    background white
    left 0
    top 5px
    border 1px solid Grey1
    display block

LoginCards()
    &::before
        LoginCard(4deg)
        top 3.5px
        z-index -1

    &::after
        LoginCard(-4deg)
        top 5px
        z-index -2

circularity()
    width arguments
    height arguments
    border-radius arguments

hide()
    display none

reallyHide()
    display none !important

marginCenter()
    margin-right auto
    margin-left auto

marginCenterWidth()
    width arguments
    marginCenter()

verticalMargin(vmarg)
    margin-top vmarg
    margin-bottom vmarg

horizontalMargin(hmarg)
    margin-left hmarg
    margin-right hmarg

verticalPadding()
    padding-top arguments
    padding-bottom arguments

horizontalPadding()
    padding-left arguments
    padding-right arguments

Blue1 = #1e2c51
Blue2 = #47567a
Blue3 = #6b7796

Purple1 = #836895
Purple2 = #64447a
Purple3 = #3b1b50

ColorCycle()
    &:nth-of-type(6n+1)
        background-color Blue1

    &:nth-of-type(6n+2)
        background-color Blue2

    &:nth-of-type(6n+3)
        background-color Blue3

    &:nth-of-type(6n+4)
        background-color Purple1

    &:nth-of-type(6n+5)
        background-color Purple2

    &:nth-of-type(6n+6)
        background-color Purple3

TextEllipsis()
    max-width arguments
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
BottomBorderRadius()
    border-bottom-left-radius arguments
    border-bottom-right-radius arguments

TopBorderRadius()
    border-top-left-radius arguments
    border-top-right-radius arguments
// Stealing material design shadows
// Mixins
// ------------------------------------

// Generator for top shadow
z-depth-top($depth = 1)
    $color =    .12, .19, .19, .21, .22
    $offset-y = 2px, 6px, 17px, 25px, 40px
    $blur =     10px, 20px, 50px, 55px, 77px

    box-shadow: 0 $offset-y[$depth] $blur[$depth] rgba(0, 0, 0, $color[$depth])

// Generator for bottom shadow
z-depth-bottom($depth = 1)
    $color =    .16, .2, .24, .22, .2
    $offset-y = 2px, 8px, 12px, 16px, 27px
    $blur =     5px, 17px, 15px, 28px, 24px

    box-shadow: 0 $offset-y[$depth] $blur[$depth] rgba(0, 0, 0, $color[$depth])

// Generator for top and bottom shadow
z-depth($depth = 1)
    box-shadow: 0 values(z-depth-bottom($depth)), 0 values(z-depth-top($depth))

// Generator animation hover and focus effect
z-depth-animation($depth, $orientation = full)
    &:hover,
    &:focus
        if $orientation == top
            z-depth-top($depth)
        else if $orientation == bottom
            z-depth-bottom($depth)
        else
            z-depth($depth)


// Classes
// ------------------------------------

z-depth-class($animation = true, $time = .28s, $function = cubic-bezier(.4, 0, .2, 1))
    for $i in 0..4
        &-{$i + 1}
            z-depth($i)
            &-top
                z-depth-top($i)
            &-bottom
                z-depth-bottom($i)

    // z-depth-animation
    if $animation
        &-animation
            &:hover
                .z-depth-1
                .z-depth-2
                .z-depth-3
                .z-depth-4
                .z-depth-5
                    transition box-shadow $time $function

White = #FFF

ResizeHook()
    position absolute
    right 0
    width 8px !important
    height 53px
    cursor col-resize
    z-index 999999
    &:hover
        background-color rgba(198, 85, 255, 0.46) !important
        border 1px White solid
        background-color rgba(198, 85, 255, 0.46) !important
        border 1px White solid

NoScrollBar()
    &::-webkit-scrollbar
        display none

QuorumScrollBar()
    &::-webkit-scrollbar
        width ScrollBarSize
        height ScrollBarSize
        background-color rgba(0, 0, 0, 0)
        -webkit-border-radius 100px

    &::-webkit-scrollbarhover
        background-color: rgba(0, 0, 0, 0.09)

    &::-webkit-scrollbar-corner, ::-webkit-resizer
        display none

    &::-webkit-scrollbar-thumb
        background Grey2
        -webkit-border-radius 100px
        background-clip padding-box
        border 3px solid rgba(0, 0, 0, 0)
        min-height 30px

    &::-webkit-scrollbar:hover ::-webkit-scollbar-thumb,
    &::-webkit-scrollbar-thumb:hover,
    &::-webkit-scrollbar-thumb:active
        background Grey3
        -webkit-border-radius 100px
        border: 3px solid rgba(0, 0, 0, 0)
        background-clip padding-box

QuorumThickScrollBar()
    QuorumScrollBar()
    &::-webkit-scrollbar
        height ThickScrollBarSize
        width ThickScrollBarSize

// For project modals
InteractionForm()
    margin-top 0px !important
    border 1px solid Grey1
    background-color white
    border-radius 4px
    padding 9px
    margin-left 0px

CogRotations()
    Transform(.25s)
    &:hover
        Rotate(30deg)
