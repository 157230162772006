@import DESIGN

.form-gridtoggle-field
    margin-top ReduxFormMarginSize

    .form-gridtoggle-header
        display flex
        justify-content space-between

        .control-label
            margin-bottom 8px

        .form-gridtoggle-error
            flex-shrink 0
            color ReduxFormErrorColor

        .form-gridtoggle-warning
            color ReduxFormWarningColor

        .gridtoggle-icon
            padding-left 10px

    .gridtoggle-error-outline
        border-color ReduxFormErrorColor

        &:focus
            ReduxFormErrorFocus()

    .gridtoggle-warning-outline
        border-color ReduxFormWarningColor

        &:focus
            ReduxFormWarningFocus()

    .grid-toggle
        .grid-toggle-button
            .gridtoggle-button
                font-size ReduxFormFontSize
                &:disabled
                    background-color QuorumGrey2
                    color white
                    border-color QuorumGrey3

    &.new-action-center__toggle-button--inline
        .form-gridtoggle-container
            background: #F1F3F5
            padding: 4px 5px
            margin-bottom: 10px
            overflow: hidden
        .grid-toggle-button
            margin-bottom: 0
            padding-right: 0
            padding-left: 0
            flex: 1
        .form-gridtoggle-container, .gridtoggle-button
            border-radius: 32px
        .grid-toggle.row
            margin-left: 0
            margin-right: 0
        .grid-toggle-option
            font-size: 12px
            color: #495057
        .gridtoggle-button
            padding: 4px 9px
            outline: none !important
        .gridtoggle-button.btn-default-white
            background: none
            border: none;
        .gridtoggle-button.btn-primary
            background: #fff
            color: inherit
            border: none
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.16)
        .gridtoggle-button.btn-primary:active, .gridtoggle-button.btn:active
            box-shadow: none
        .gridtoggle-button.btn-primary .grid-toggle-option
            font-weight: 500
            color: #212529

    &.new-action-center__toggle-button--single-option
        .form-gridtoggle-container
            background: transparent

    &.new-action-center__toggle-button--block
        display: flex
        gap: 5px
        flex-direction: column
        margin-top: 18px

        .form-gridtoggle-container
            margin: 0
            padding: 0
        .form-gridtoggle-header
            display: flex
            align-items: center
            justify-content: center
            .control-label
                font-weight: 700
                margin-bottom: 0
        .grid-toggle.row
            display: flex
            gap: 8px
            flex-direction: column
            align-items: center
            align-self: stretch
        .grid-toggle-button
            width: 514px
            margin: 0

            @media (max-width: 768px)
                width: 100%
            margin: 0
            .btn-default-white
              background-color: #F1F3F5
            .gridtoggle-button
                padding: 12px 16px
                border: none
                border-radius: 8px
                .grid-toggle-option
                  font-family: "Helvetica Neue"
                  font-size: 18px
                  font-style: normal
                  font-weight: 500
                  line-height: 145%
                  display: flex
                  justify-content: space-between
                  align-items: center
                  width: 100%
                  [id^="donation-tier-hyphen-"]
                    display: none
                  [id^="donation-tier-frequency-"]
                    color: #828E98
                    font-size: 14px
                    font-weight: 500
                  [id^="donation-tier-label"]
                    font-size: 15px
                  [id^="donation-tier-value"]
                    font-weight: 700
            .btn-primary
              padding: 0
              background-color: #fff
              outline-width: 2px
              box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.40);
              .grid-toggle-option
                padding: 12px 16px
                [id^="donation-tier"]
                  color: #333
