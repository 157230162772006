// Main color used throughout the site
QuorumBlue = #503ABD
LightBlue = #e7eaf3 // <--- TODO this is not actually LightBlue

// StripeColor used in SearchBarModal
StripeColor = lighten(QuorumBlue, 90%)

// static file stub
StaticFiles = "https://quorum-static.s3.amazonaws.com"

// Main greys used throughout the site. First two used most often
QuorumGrey1 = #ecf0f1
QuorumGrey2 = #b4bcc2
QuorumGrey3 = #95a5a6
QuorumGrey4 = #7b8a8b
QuorumGrey5 = #ccc
QuorumGrey6 = #f5f5f5
QuorumGrey7 = #777
DarkGrey = #687374
PrivateGrey = #e6e6e6
StandardGrey = #666
SelectBorderGrey = #b6c0da
DarkerPorcelain = #f1f3f4

LightTransparentGrey = rgba(0, 0 , 0 , 0.04)

LetterPaperSizeWidth = 21.59cm
LetterPaperSizeHeight = 23.9cm

HeaderColor = lighten(QuorumBlue, 90%)
StripeColor = HeaderColor
HeaderBorderColor = lighten(QuorumBlue, 80%)
HeaderBorderBlueColor = #526aa4

IndependentColor = QuorumGrey2

LibertarianColor = QuorumGrey2

// Logo Colors
DarkPurple = #7259EF // Quorum
MiddlePurple = #5f2b7b
LightPurple = #503ABD // Data Driven Politics
VeryLightPurple = lighten(LightPurple, 80%)

// Text Colors
BodyTextColor = #0e121c
HyperlinkColor = #7259EF
White = #fff
Black = #000
CloudBurst = #2F1980

// Republican Colors
RepublicanMainColor = #D62728
RepublicanDarkColor = #951b1c // Borders, etc.
RepublicanLightColor = #ff3739 // Hovers, etc

// Democrat Colors
DemocratMainColor = #1f77b4
DemocratDarkColor = #15537d
DemocratLightColor = #2caaff

// Puerto Rico Colors. Stole these bois by eye dropping the party logos lol.
// PNP
PNPMainColor = #203d8a
// PPD
PPDMainColor = #e81728
// PIP
PIPMainColor = #227d16
// PPT
PPTMainColor = #62378d

// EU Colors!!
// EPP
EPPMainColor = #3399FF

// S&D
SDMainColor = #FF0000

// ECR
ECRMainColor = #0000FF

// ALDE
ALDEMainColor = #FFD700

// GUE/NGL
GUENGLMainColor = #990000

// Greens-EFA
GreensEFAMainColor = #009900

// EFDD
EFDDMainColor = #24B9B9

// ENL
ENLMainColor = #2B3856

// NI
NIMainColor = QuorumGrey5

// PFE
PFEMainColor = #1F578A

// ESN
ESNMainColor = #2F1C59

// Other
OtherColor1 = #4D2D5F
OtherColor2 = #2F3A54
OtherColor3 = #2B1F51

// Hover Color (Used in spreadsheet, manage lists)
HoverColor = #D3CFDD
DarkHoverColor = darken(HoverColor, 20)

// Social Colors
FacebookBlue = #3B5998
TwitterLightBlue = #00aced
TwitterBlue = #4099FF
TwitterDarkBlue = #0084B4
TwitterDarkestBlue = #0079A5
LinkedInBlue = #0177b5
GooglePlusOrange = #dd5044
PinterestRed = #d8121d

MediumGreen = #02b875
YoutubeRed = #cc181e
YouTubeAPIRed = #FF0000
InstagramPurpleViolet = #8a3ab9
InstagramBlue = #4c68d7
InstagramMaroon = #cd486b
InstagramOrange = #fbad50
InstagramYellow = #fccc63
InstagramRedViolet = #bc2a8d
InstagramRedOrange = #e95950

ActionButtonHover = #adadad
DropdownItemHover = #ebebeb
SearchGraphBorder = #DDD
SupporterColor = #555
PCardColor = #0088cc

// VARIABLES
BorderRadius = 4px
BorderRadiusListItems = 8px
LightBorderColor = #ced5e7

StatGrey = #6e7890

CokeRed = #F40009
CokeDarkRed = #900E0E
LineHeight = 1.428571429

Texture(u, n="5fb28db9f41c")
    return url(StaticFiles + '/static/frontend/images/textures/' + u + ".png?" + n)

BrightSquares = Texture('bright-squares')
AlwaysGrey = Texture('always-grey')
BlackThread = Texture('black-thread')
Cartographer = Texture('cartographer')
CheckeredLight = Texture('checkered-light')
Diamonds = Texture('diamonds')
Dimension = Texture('dimension')
DoubleLined = Texture('double-lined')
FakeLuxury = Texture('fake-luxury')
LightHoneyComb = Texture('light-honeycomb')
Psychedelic = Texture('psychedelic')

QuorumBlueRGBA(avalue=1)
    rgba(48, 62, 96, avalue)

PageHeaderPadding = 10px
PageHeaderFontSize = 28px

// PrivateCommentColor = #5ab4c2
// Mixins of textures
PurpleBoxes()
    background-image BrightSquares
    background-color DarkPurple
BlueWeb()
    background-image AlwaysGrey
    background-color QuorumBlue

// http://kinzler.com/picons/ftp/img/colorset.gif
// http://cs.gettysburg.edu/~duncjo01/archive/icons/aicons/
VotePassedColor = #2E8B57
VoteFailedColor = #FF510C
VotePresentColor = #FFD700
VoteMissedColor = #A020F0
VoteUnknownColor = #BEBEBE

Red = VoteFailedColor

// new color scheme

Violet0 = #FBFAFE
Violet1 = #EBEAFD
Violet2 = #D9D8FC
Violet3 = #BDB8FA
Violet4 = #9B8FF6
Violet5 = #7259EF
Violet6 = #6149D6
Violet7 = #503ABD
Violet8 = #3E2AA3
Violet9 = #2F1980

Gray0 = #FAFBFC
Gray1 = #F1F3F5
Gray2 = #E9ECEF
Gray3 = #DEE2E6
Gray4 = #CED4DA
Gray5 = #ADB5BD
Gray6 = #868E96
Gray7 = #495057
Gray8 = #343A40
Gray9 = #212529

// When you hover over clickable elements they should
// darken 10%
clickable(c)
    &:hover
        color darken(c, 10%)
FrameSize = 60px
ScrollBarSize = 14px
ThickScrollBarSize = ScrollBarSize + 4px

LargeFontSize = 16px

SidebarBorderColor = #3c4147
TopBarBorderColor = #c6d0da
TopBarTextColor = White
TopBarHoverColor = #5f6f81

SidebarGradient()
    background: linear-gradient(rgba(white, 0.96), rgba(white, 0.96)), QuorumBlue

// colors for notifications
UnseenColor = #D5D7DC
SeenColor = white
SeenFadeDuration = .1s

FormLabelSize = 14px
FormFieldHeight = 44px

DropdownStripeColor = rgba(QuorumGrey1, .5)
StatListLength = 405px
QuorumBorder = 1px solid QuorumBlue
BannerSize = 100px
InlineHoverColor = #fbfcfc
SearchPlusIcon = "\F00E"

InteractionLoggerSpacing = 15px

SelectDropdownColor = #b2c1c2

SearchifyColor = rgba(48, 62, 96, 0.28)
SearchifyHoverColor = rgba(48, 62, 96, 0.04)
SearchifyDocumentHoverColor = rgba(48, 62, 96, 0.34)

ProfileDetailGrey = #808080

.disable-with-tooltip
    opacity 0.65

BorderColor = #526aa4
FormControlBorderColor = #dce4ec

// Marketing Site
DefaultFontWeight = 300
MobileWidth = 992px
SmallMobileWidth = 768px
LoginMobileWidth = 800px

SectionHeaderFontWeight = 200
SectionHeaderFontSize = 24px

UnsubscribeMobileWidth = 800px
NavBarBreakPoint = 884px

RequestDemoPurple = #8a3bb7
RequestDemoBorderPurple = #563767
RequestDemoHoverPurple = #854ea4
EmailSignupBorder = #dda0ff

FeedItemBorderColor = rgb(221,226,238)

RequestDemoBackgroundColor = rgba(61, 50, 98, 0.92)
RequestDemoBackgroundColorNoTransparency = rgba(61, 50, 98, 1)
RequestDemoHoverBackgroundColor = rgba(43,31,81,0.99) !important

// Redux form variables
ReduxFormLineHeight = 44px
ReduxFormErrorColor = #E76B74
ReduxFormWarningColor = #FACE00
ReduxFormFontSize = 14px
ReduxFormInputSize = 16px
ReduxFormMarginSize = 10px

ReduxFormErrorFocus()
    border-color ReduxFormErrorColor
    outline 0
    -webkit-box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormErrorColor, .6)
    box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormErrorColor, .6)

ReduxFormWarningFocus()
    border-color ReduxFormWarningColor
    outline 0
    -webkit-box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormWarningColor, .6)
    box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormWarningColor, .6)

// Help Center
HelpCenterBlueBackground = rgba(48, 62, 96, 0.7)
HelpCenterHeroImageHeight = 235px
HelpCenterQSExitMargin = 20px
HelpCenterPanelHover = #dee2e3

HelpCenterLoadingText()
    text-align center
    font-size 42px
    font-weight 300
    color QuorumBlue
    margin-top 20px

// NEW FORMS
ErrorPoppy = #A80903
ErrorRed = #D81C15
WarningMarigold = #ED7806
