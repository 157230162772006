@import DESIGN

.custom-event-details-main
    background-color white
    border-radius 4px
    margin-top 15px

    .custom-event-details-header
        color white
        font-size 19px
        padding 7px
        padding-left 10px

    .custom-event-details-item
        padding-left 10px
        margin-top 10px
        margin-bottom 10px
        font-size 18px

        .custom-event-details-item-label
            font-weight 600

        .custom-event-details-item-text
            display inline-block
