@import DESIGN

.form-date-field
    margin-top ReduxFormMarginSize
    margin-bottom ReduxFormMarginSize
    label.control-label
        font-size ReduxFormFontSize
    .date-picker-container
            position relative
            &.active
                width calc(100% - 12px)
                .fa-times
                    cursor pointer
                    position absolute
                    top 11px
                    right -18px
    .input-group.date
        width 100%
        font-weight normal
        input
            text-align center
            font-size ReduxFormInputSize
            color QuorumBlue
            border-radius BorderRadius
            line-height ReduxFormLineHeight
            border-radius 4px
            height 44px

.bootstrap-datetimepicker-widget
    width: auto
    .table-condensed
        width: 100%

    .select-error-outline
        .rdt
            input
                border-color ReduxFormErrorColor

                &:focus
                    ReduxFormErrorFocus()

    .select-warning-outline
        .rdt
            input
                border-color ReduxFormWarningColor

                &:focus
                    ReduxFormWarningFocus()

.swal-modal
    .input-group.date
        width auto
