@import DESIGN

SelectDropdownColor = #b2c1c2
QuorumBlue = #503ABD
HyperlinkColor = #854EA4
SelectFontSize = 15px
SelectMultiFontSize = 14px
SelectArrowFontSize = 27px
SelectArrowHeight = 23px

.quorum-reactselect-wrapper
    &.is-design-system
        .Select__menu
            border none
            border-radius 4px
            margin-top 7px
            box-shadow 0px 2px 5px 0px rgba(0, 0, 0, 0.3)
        .Select__menu-list
            font-size SelectFontSize
            line-height 17px
            .Select__option
                height 30px
                color QuorumBlue !important
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
                &.Select__option--is-focused
                    background-color Violet1
                &.Select__option--is-selected
                    background-color QuorumBlue
                    & > span > svg
                        color white
                &.Select__option--is-disabled
                    font-weight bold
                    font-size larger
                    color QuorumBlue
        .Select__indicator
            color QuorumBlue
        .Select__indicator-separator
            display none
        .Select__control
            border-color QuorumBlue
            border-radius 4px
        .Select__control--menu-is-open
            .Select__dropdown-indicator
                transform rotate(180deg)

    &.is-searchify-select
        position relative

        .Select
            .Select__control
                justify-content flex-end
            .Select__indicators
                .Select__indicator-separator
                    margin-right 8px
                .Select__indicator
                    &.Select__dropdown-indicator
                        svg
                            visibility hidden
        .NewSelect
            .NewSelect__control
                justify-content flex-end
            .NewSelect__indicators
                .NewSelect__indicator-separator
                    margin-right 8px
                .NewSelect__indicator
                    &.NewSelect__dropdown-indicator
                        svg
                            visibility hidden
                .NewSelect____indicator
                    &.NewSelect____dropdown-indicator
                        svg
                            visibility hidden
        // the searchify magnifying glass button
        .launch-select-searchify
            display flex
            justify-content center
            align-items center
            top 50%
            transform translateY(-50%)
            right 12px
            position absolute
            font-size 22px
            cursor pointer
            color SelectDropdownColor
            z-index 1
            &:hover
                color QuorumBlue
        .launch-new-select-searchify
            display flex
            justify-content center
            align-items center
            top 50%
            transform translateY(-50%)
            right 12px
            position absolute
            font-size 18px
            cursor pointer
            color QuorumBlue
            z-index 1
            &:hover
                color SelectDropdownColor

.Select
    font-size 16px

    .Select__indicators
        .Select__indicator
            &.Select__clear-indicator
                svg
                    cursor pointer

    &.is-open
        // .Select-arrow
        .Select__dropdown-indicator,
        .Select__indicator-separator
            height SelectArrowHeight
            display none
        // .Select-arrow
        .Select__dropdown-indicator
            height 23px

    .Select__control
        border thin solid LightBorderColor
        min-height 44px

        &:hover
            box-shadow none

        &.Select__control--is-focused
            border-color #b6c0da
            box-shadow none
            &.Select__control--menu-is-open
                border-color QuorumBlue

        .Select__value-container
            padding 2px 0px
            min-width 0
            flex-direction row
            align-items center

            &.Select__value-container--is-multi
                &.Select__value-container--has-value
                    padding-left 4px

            .Select__placeholder
                line-height 17px
                white-space nowrap
                width 100%
                overflow hidden
                text-overflow ellipsis
                padding-left 4px

            .Select__single-value
                padding-left 8px

            .Select__input
                padding-left 3px

    // .Select-value,
    .Select__multi-value
        line-height 20px
        color Gray7
        font-size SelectMultiFontSize
        background-color Gray1
        border-radius 4px
        justify-content space-between
        margin-left 0

        // .Select-value-icon
        .Select__multi-value__remove
            border-radius 0
            cursor pointer

            &:hover, &:focus
                color Gray7
                background-color Gray2

        // .Select-value-label
        .Select__multi-value__label
            line-height 20px
            color Gray7
            font-size SelectMultiFontSize
            white-space initial

    .Select__menu
        margin-top 0px
        border-radius 0
        z-index 3
        box-shadow none
        .Select__menu-list
            padding 0
            .Select__option
                &.Select__option--is-focused
                    background-color Violet1
                &.Select__option--is-selected
                    background-color QuorumBlue
                    & > span > svg
                        color white
        // &.Select__menu-list--is-multi

.filter-side-panel
    .Select
        margin 10px
        .Select__control
            .Select__value-container
                align-items normal
                flex-direction column
                flex-wrap nowrap
    .is-searchify-select
        .launch-select-searchify
            right 24px

// TODO: this should be re-factored or deleted
.react-renderer
    .mention-select
        background-color white
        border-bottom thin solid #503ABD
        border-left thin solid #503ABD
        border-right thin solid #503ABD
        max-height 320px
        min-width 200px
        overflow-y: scroll
        position absolute
        width auto

.mention-select
    position absolute
    width 200px
    .Select-control
        display none
    .Select
        .Select__control
            display none
        .Select__menu
            border-top-width thin
    > span.loading
        position absolute
        padding 2px 5px
        font-size 15px
        border 1px solid #ccc
        border-radius 3px
        color QuorumBlue
        width 200px
        height 30px

.at-mention-helper
    position absolute
    bottom 14px
    // position relative
    // bottom 30px
    left 14px
    cursor pointer
    background-color Violet5
    color white
    border-radius 4px
    height 20px
    width 20px
    display flex
    justify-content center
    align-items center

    &.DS
        top calc(100% - 28px)
        left calc(100% - 28px)

    &.mantine-textarea
        top calc(100% - 48px)

    &.mentioning
        opacity .7

    &:hover
        background-color #521B71

.at-mention
    background-color Violet1
    color Violet8
    font-weight bold
    padding 2px

.NewSelect
    .NewSelect__control
        box-sizing border-box

        .NewSelect__value-container
            padding: 0 5px
