@import DESIGN

.custom-events-page
    .custom-events-page-nav
        margin-top 20px
        padding-left 15px

        span.button-label
            padding-left 6px

.custom-events-participants-table
    #sheet-column
        #resource-table-wrapper
            .sheet-wrapper-inner
                height calc(100vh - 295px)
    .row
        &>*
            width auto
